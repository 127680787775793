import styled from "styled-components";
import Button from "../../Atoms/Button";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  height: 100vh;
  width: 0;
  overflow: hidden;

  position: absolute;
  right: 0;

  background: #fff;
  border-radius: 6px 0px 0px 6px;

  z-index: 999;

  transition: all 0.3s ease-out 0s;

  &.active {
    max-width: 500px;
    width: 500px;

    @media (max-width: 768px) {
      min-height: 100vh;
      height: auto;
      max-width: 100%;
      width: 100%;

      overflow-y: scroll;
    }
  }
`;

export const TitleSection = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  height: 40px;
  width: 100%;

  background: #fed44a;
  border-radius: 6px 6px 0px 0px;

  color: #000;

  font-family: "Roboto", sans-serif;
  font-weight: bolder;

  .close_btn {
    position: absolute;
    left: 24px;
    color: #000;

    transition: filter 0.2s, color 0.2s;
    &:hover {
      filter: opacity(0.6);
      cursor: pointer;
      /* color: red; */
    }
  }
`;

export const ItensSection = styled.div`
  display: flex;
  padding: 0 1rem;
  flex-direction: column;

  overflow-y: scroll;
`;

export const CouponContainer = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
  border: solid 1px rgba(0, 0, 0, 0.3);
  border-radius: 0.5rem;

  > p {
    margin-right: 1rem;
    text-align: center;
  }

  button {
    width: 80px;
    height: 40px;
    margin: 0;
    margin-left: auto;

    border-radius: 0.4rem;
  }

  padding: 1rem 2rem;

  @media (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 1rem;

    p {
      min-width: 100%;
      padding: 0;
      margin: 0;
    }

    input {
      margin: 0;
      height: 35px;
      max-height: 35px;
      width: 65%;
    }
  }

  @media (max-width: 1200px) {
    input {
      margin: 0;
    }
  }
`;

export const ButtonSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 1rem 1rem;
  width: 100%;

  margin-top: auto;
  border-top: solid 3px gray;

  .total {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;

    span {
      font-weight: bold;
    }

    p {
      font-size: 12px;
    }

    strong {
      font-size: 16px;
    }

    .bold {
      font-weight: bold;
      font-size: 16px;
    }

    .oldPrice {
      text-decoration: line-through;
    }
  }

  .btns {
    display: flex;
    width: 100%;
    justify-content: space-around;
  }

  @media (max-width: 768px) {
    margin-top: 0;

    .btns {
      margin-bottom: 7rem;
    }
  }

  @media not all and (min-resolution: 0.001dpcm) {
    @media (max-width: 768px) {
      .safari_only {
        .btns {
          margin-bottom: 4rem;
        }
      }
    }
  }
`;

export const StyledButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 40%;

  svg {
    color: #000;
  }

  p {
    margin-left: 0.8rem;
  }

  &.pix-btn {
    img {
      filter: invert(1);
    }
    color: #fff;
    background: #49baae;
  }

  &.apply-coupon-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 1.5rem;

    p {
      margin: 0;
    }

    svg {
      color: #fff;
      font-weight: bold;
    }
  }

  @media (max-width: 1200px) {
    padding: 0 12px;

    p {
      display: none;
    }

    &.apply-coupon-btn {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-left: 1rem;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
`;
