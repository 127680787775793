import React, { useCallback, useRef, useState } from "react";
import { FiUser, FiLock } from "react-icons/fi";
import * as Yup from "yup";

import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";
import getValidationErrors from "../../../utils/getValidationErrors";

import { useAuth } from "../../../hooks/auth";
import { useToast } from "../../../hooks/toast";
import { useHistory } from "react-router-dom";

import Input from "../../../components/Atoms/Input";
import Loading from "../../../components/Atoms/Loading";

import bannerLoginRaiz from "../../../assets/images/banner-login-raiz.png";
import bannerLoginRaiz2 from "../../../assets/images/RaízdaQuestão.png";

import bannerLoginUnipSaoJoao from "../../../assets/images/banner-login-unipsaojoao.png";
import bannerLoginUnipSaoJoao2 from "../../../assets/images/UnipSaoJoao.png";

import {
  Container,
  ContentWrapper,
  LoginWrapper,
  FormWrapper,
  StyledButton,
  LogoWrapper,
} from "./styles";
import { useInitialRoute } from "../../../hooks/InitialRouteContext";

interface DataFormInfo {
  username: string;
  password: string;
}

const Login: React.FC = () => {
  const [isLogging, setIsLogging] = useState(false);

  const formRef = useRef<FormHandles>(null);

  const { signIn } = useAuth();
  const { addToast } = useToast();
  const { push } = useHistory();

  const handleLogin = useCallback(
    async (data: DataFormInfo) => {
      setIsLogging(true);
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          username: Yup.string().required("Usuário obrigatório!"),
          password: Yup.string().required("Senha obrigatória!"),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const targetUrl = await signIn({
          username: data.username,
          password: data.password,
        });

        setIsLogging(false);

        push(targetUrl);
      } catch (err) {
        console.log("problemns here", err);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          setIsLogging(false);
        } else {
          addToast({
            type: "error",
            title: "Erro na autenticação :(",
            description:
              "Oops... parece que algo deu errado, confira seu usuário e senha!",
          });
          setIsLogging(false);
        }
      }
    },
    [addToast, signIn, push]
  );

  const { subdomainUrlImg } = useInitialRoute();

  let urlAtual =
    process.env.NODE_ENV === "development"
      ? `${process.env.REACT_APP_WHITELABEL}`
      : window.location.href;
  let splitUrlProd = urlAtual.split(".");
//   let subdomain = splitUrlProd[0].includes("www")
//     ? ["", splitUrlProd[1]]
//     : splitUrlProd[0].split("//");
  let subdomain = ["","sedi",""];

  return (
    <Container>
      <ContentWrapper>
        <LoginWrapper>
          <img src={subdomainUrlImg} alt="logo" onClick={() => push("./")} />
          <Form ref={formRef} onSubmit={handleLogin} className="formStyle">
            <FormWrapper>
              <h3>LOGIN</h3>
              <Input
                name="username"
                icon={FiUser}
                placeholder="Digite seu e-mail"
                style={{ width: 300 }}
                enabled={!isLogging}
              />
              <Input
                name="password"
                icon={FiLock}
                type="password"
                placeholder="Sua senha"
                autoComplete="on"
                enabled={!isLogging}
              />
              <button
                className="forgot"
                type="button"
                onClick={() => push("/password/forgot")}
              >
                esqueci minha senha
              </button>
              <StyledButton type="submit" enabled={!isLogging}>
                {isLogging ? <Loading size={2} /> : "ENTRAR"}
              </StyledButton>

              <StyledButton
                onClick={() => push("/signup")}
                enabled={!isLogging}
                className="signup"
              >
                Crie uma conta
              </StyledButton>
            </FormWrapper>
          </Form>
        </LoginWrapper>
        {subdomain[1] === "raizdaquestao" && (
          <LogoWrapper>
            <img src={bannerLoginRaiz2} alt="" />
          </LogoWrapper>
        )}
        {subdomain[1] === "unipsaojoao" && (
          <LogoWrapper>
            <img src={bannerLoginUnipSaoJoao2} alt="" />
          </LogoWrapper>
        )}
      </ContentWrapper>
    </Container>
  );
};

export default Login;
