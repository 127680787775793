import styled, { css, FlattenSimpleInterpolation } from "styled-components";
import { PaymentStatus } from "../../../../../models/PaymentModels";

const errorLabelStyle = css`
  background-color: #ff9494;
`;

const waitingLabelStyle = css`
  background-color: #f29339;
`;

const paidLabelStyle = css`
  background-color: #4bb543;
`;

const freeLabelStyle = css`
  background-color: #39abe1;
`;

interface IStatusLabel {
  type: PaymentStatus;
}

const labelStyles: { [key in PaymentStatus]: FlattenSimpleInterpolation } = {
  ERROR: errorLabelStyle,
  FREE: freeLabelStyle,
  WAITING_PAYMENT: waitingLabelStyle,
  PAID: paidLabelStyle,
};

export const StatusLabel = styled.label<IStatusLabel>`
  color: white;
  font-size: 10px;
  font-weight: bold;
  border-radius: 18px;
  padding: 6px;
  ${({ type }) => labelStyles[type] ?? ""}
`;
