import { Thead, Tr } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { BackofficeUser } from "../../../../../models/UserModels";
import {
  Table,
  Tbody,
  PageCount,
  Th,
  Td,
} from "../../../../_layout/AdminLayout.styles";

import * as S from "./styles";

interface IUsersTable {
  data: BackofficeUser[];
  currentIndex: number;
  lastIndex: number;
  onSelectItem: (args: { cartid: string; transactionid: string }) => void;
}

const UsersTable: React.FC<IUsersTable> = ({
  data,
  currentIndex,
  lastIndex,
  onSelectItem,
}) => {
  return (
    <>
      <Table breakpoint={1000}>
        <Thead>
          <Tr>
            <Th>{"    "}</Th>
            <Th>Nome</Th>
            <Th>Nome de usuário</Th>
            <Th>E-mail</Th>
            <Th>Status</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((item) => (
            <Tr key={item.userid}>
              <Td>
                <S.ImageContainer>
                  {item.imageurl.length > 10 ? (
                    <img src={item.imageurl} width={20} alt="profile" />
                  ) : (
                    <p style={{ color: "white" }}>
                      {item.fullname[0].toLocaleUpperCase()}
                    </p>
                  )}
                </S.ImageContainer>
              </Td>
              <Td>{item.fullname}</Td>
              <Td>{item.username}</Td>
              <Td>{item.email}</Td>
              <Td>{item.status}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <PageCount>
        Pagina {currentIndex} de {lastIndex}
      </PageCount>
    </>
  );
};

export default UsersTable;
