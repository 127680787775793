import React, { useCallback, useEffect, useRef, useState } from "react";
import { FiUser, FiLock, FiMail } from "react-icons/fi";
import * as Yup from "yup";

import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";
import getValidationErrors from "../../../utils/getValidationErrors";

import { useAuth } from "../../../hooks/auth";
import { useToast } from "../../../hooks/toast";
import { useHistory } from "react-router-dom";

import Input from "../../../components/Atoms/Input";
import Loading from "../../../components/Atoms/Loading";
import ImageUpload from "../../../components/Atoms/ImageUploadV2";
import Dropdown from "../../../components/Atoms/Dropdown";

import {
  getStudentPhase,
  getStudentPhaseLevels,
  schoolOptions,
} from "../../../utils/mocks";
import { toast } from "react-toastify";
import {
  Container,
  ContentWrapper,
  LoginWrapper,
  FormWrapper,
  StyledButton,
} from "./styles";
import { useInitialRoute } from "../../../hooks/InitialRouteContext";
import { MdDomainVerification } from "react-icons/md";

interface DataFormInfo {
  username: string;
  fullName: string;
  email: string;
  password: string;
  confirmPassword: string;
  codeVerification?: string;
}

const Landing: React.FC = () => {
  const [isLogging, setIsLogging] = useState(false);
  const [imgUrl, setImgUrl] = useState("");
  const [schoolId, setSchoolId] = useState("");
  const [schoolPhase, setSchoolPhase] = useState("");
  const [schoolLevel, setSchoolLevel] = useState("");
  const [addToastState, setAddToastState] = useState(false);

  const formRef = useRef<FormHandles>(null);

  const { subdomainUrlImg } = useInitialRoute();

  const { signUp, returnBackend, setReturnBackend } = useAuth();
  const { addToast } = useToast();
  const { push } = useHistory();

  const handleChangeImg = (img: string): void => {
    setImgUrl(img);
  };

  const handleChangeSchoolPhase = (value: string): void => {
    setSchoolPhase("");
    setTimeout(() => {
      setSchoolPhase(value);
    }, 250);
  };

  let urlAtual =
    process.env.NODE_ENV === "development"
      ? `${process.env.REACT_APP_WHITELABEL}`
      : window.location.href;
  let splitUrlProd = urlAtual.split(".");
//   let subdomain = splitUrlProd[0].includes("www")
//     ? ["", splitUrlProd[1]]
//     : splitUrlProd[0].split("//");
  let subdomain = ["","sedi",""];

  const handleLogin = useCallback(
    async (data: DataFormInfo) => {
      setIsLogging(true);

      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          username: Yup.string().required("Usuário obrigatório!"),
          fullName: Yup.string().required("Nome completo obrigatório!"),
          email: Yup.string()
            .email("E-mail inválido")
            .required("E-mail obrigatório!"),
          password: Yup.string().required("Senha obrigatória!"),
          confirmPassword: Yup.string().oneOf(
            [Yup.ref("password"), undefined],
            "As senhas não são iguais"
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await signUp({
          email: data.email,
          password: data.password,
          username: data.username,
          imageUrl: imgUrl ?? " ",
          fullName: data.fullName,
          schoolid: schoolId ? schoolId : subdomain[1].toUpperCase(),
          levelid: schoolLevel ? schoolLevel : "1",
          roomid: schoolPhase ? schoolPhase : subdomain[1].toUpperCase(),
          codeVerification: data.codeVerification ? data.codeVerification : "",
        });

        setIsLogging(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          setIsLogging(false);
        } else {
          setAddToastState((current) => !current);

          setIsLogging(false);
        }
      }
    },

    [addToast, signUp, imgUrl, schoolId, schoolLevel, schoolPhase]
  );

  // useEffect(() => {
  //   if (returnBackend != "") {
  //     addToast({
  //       type: "error",
  //       title: "Um erro ocorreu :(",
  //       description: returnBackend,
  //     });
  //     setReturnBackend("");
  //   }
  // }, [addToastState]);

  const InputCode = () => {
    if (
      subdomain[1] === "bindemy" ||
      subdomain[1] === "sedi" ||
      subdomain[1] === "raizdaquestao" ||
      subdomain[1] === "xlr8academy" ||
      subdomain[1] === "propaga"
    ) {
      return null;
    } else {
      return (
        <Input
          name="codeVerification"
          icon={MdDomainVerification}
          placeholder="Codigo de verificação escolar"
          containerStyle={{ width: "100%" }}
          enabled={!isLogging}
        />
      );
    }
  };

  return (
    <Container>
      <ContentWrapper>
        <LoginWrapper>
          <img
            src={subdomainUrlImg}
            alt="logo"
            onClick={() => push("./login")}
          />
          <ImageUpload
            type="simple"
            setImg={handleChangeImg}
            customChild={false}
          />
          <Form ref={formRef} onSubmit={handleLogin}>
            <FormWrapper>
              <h3>Cadastro</h3>
              <Input
                name="username"
                icon={FiUser}
                placeholder="Digite seu usuário"
                style={{ width: 400 }}
                enabled={!isLogging}
                containerStyle={{ width: "100%" }}
              />
              <Input
                name="fullName"
                icon={FiUser}
                placeholder="Digite seu nome completo"
                enabled={!isLogging}
                containerStyle={{ width: "100%" }}
              />
              <InputCode />
              <Input
                name="email"
                icon={FiMail}
                placeholder="E-mail"
                enabled={!isLogging}
                containerStyle={{ width: "100%" }}
              />
              <Input
                name="password"
                icon={FiLock}
                type="password"
                placeholder="Sua senha"
                enabled={!isLogging}
                containerStyle={{ width: "100%" }}
              />
              <Input
                name="confirmPassword"
                icon={FiLock}
                type="password"
                placeholder="Confirmar senha"
                enabled={!isLogging}
                containerStyle={{ width: "100%" }}
              />

              <StyledButton
                enabled={!isLogging}
                className="signup"
                type="submit"
              >
                {isLogging ? <Loading size={2} /> : "Registrar"}
              </StyledButton>
            </FormWrapper>
          </Form>
        </LoginWrapper>
      </ContentWrapper>
    </Container>
  );
};

export default Landing;
