import styled, { css } from "styled-components";
import Tooltip from "../Tooltip";

interface ContainerProps {
  isFocused: boolean;
  hasValue: boolean;
  hasError: boolean;
  enabled: boolean;
  customBgColor: string | undefined;
  customRadius: string | undefined;
  customWidth: string | undefined;
}

export const Container = styled.div<ContainerProps>`
  background: ${(props) =>
    props.customBgColor ? props.customBgColor : "#7a8095"};

  border-radius: ${(props) =>
    props.customRadius ? `${props.customRadius}` : "30px"};

  display: flex;
  align-items: center;

  padding: 16px;
  width: ${(props) => (props.customRadius ? `${props.customWidth}` : "75%")};

  border: 2px solid #7a8095;
  color: white;

  & + div {
    margin-top: 8px;
  }
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);

  transition: border 0.4s, box-shadow 0.4s;

  ${(props) =>
    props.hasError &&
    css`
      border: 2px solid #c53030;
    `}

  ${(props) =>
    props.isFocused &&
    css`
      border: 2px solid #ffa22b;
      color: #ffa22b;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.4);
    `}

  ${(props) =>
    props.hasValue &&
    css`
      border: 2px solid #ffa22b;
      color: #ffa22b;
    `}

  ${(props) =>
    !props.enabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}

  input {
    background: transparent;
    flex: 1;
    border: 0;

    color: #fff;
    &::placeholder {
      color: #fff;
    }

    margin-right: 40px;

    @media (max-width: 980px) {
      margin-right: 10px;
    }
  }

  svg {
    margin-right: 16px;
  }
`;

export const Error = styled(Tooltip)`
  height: 20px;

  svg {
    margin-right: 0px;
  }

  span {
    color: #fff;
    background-color: #c53030;

    &::before {
      border-color: #c53030 transparent;
    }
  }
`;

export const EyePassword = styled.div`
  height: 20px;
  margin-left: 8px;

  svg {
    margin-right: 0px;
    color: #b4bad4;

    transition: color 0.4s;

    &:hover {
      cursor: pointer;
      color: #ffa22b;
    }
  }
`;
