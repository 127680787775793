export const getHeaderTabs = (
  role: string,
  exclusive?: boolean
): Array<{ key: string; value: string }> => {
  if (!exclusive) {
    switch (role) {
      case "courses":
        return [
          { key: "courses", value: "Cursos" },
          // { key: "recordedclasses", value: "Aulas Gravadas" },
          { key: "liveclasses", value: "Ao Vivo" },
          { key: "doubts", value: "Plantão" },
          // { key: "activities", value: "Atividades" },
          // { key: "messages", value: "Mensagens" },
        ];
      case "noDoubts":
        return [
          { key: "courses", value: "Cursos" },
          // { key: "recordedclasses", value: "Aulas Gravadas" },
          { key: "liveclasses", value: "Ao Vivo" },
          // { key: "activities", value: "Atividades" },
          // { key: "messages", value: "Mensagens" },
        ];
      case "onlyCourses":
        return [{ key: "courses", value: "Cursos" }];
      case "onlyLiveClasses":
          return [{ key: "liveclasses", value: "Ao Vivo" }];      
      case "coursesAndLiveClasses":
          return [{ key: "courses", value: "Cursos" },
          { key: "liveclasses", value: "Ao Vivo" },];
      case "admin":
        return [{ key: "admin", value: "Minhas Vendas" }];
      default:
        return [
          // { key: "recordedclasses", value: "Aulas Gravadas" },
          { key: "liveclasses", value: "Ao Vivo" },
          { key: "doubts", value: "Plantão" },
          // { key: "messages", value: "Mensagens" },
        ];
    }
  }
  return [];
};

export const getUserRole = (
  profileId: string,
  levelId?: string,
  schoolId?: string
): string => {
  switch (schoolId) {
    case "BINDEMY":
      return "onlyCourses";
    case "RAIZDAQUESTAO":
      return "onlyCourses";
    case "UNIPSAOJOAO":
      return "coursesAndLiveClasses";
    case "SETEPEBARAPIRACA":
      return "onlyLiveClasses";
    case "NOVASDEPAZ":
        return "onlyLiveClasses";    
    case "IBEM":
        return "onlyLiveClasses";
    case "Cursos":
    case "SEDI":
      return "onlyCourses";
    case "SETEPEB":
      return "onlyLiveClasses";
    case "SELETIVO":
      return "onlyLiveClasses";
    case "XLR8ACADEMY":
      return "onlyCourses";
    case "SIM":
      return "onlyCourses";
    default:
      switch (profileId) {
        case "Parent":
          return "noDoubts";
        default:
          switch (levelId) {
            case "2Ano":
            case "3Ano":
            case "4Ano":
            case "5Ano":
              return "noCourses";
            default:
              return "courses";
          }
      }
  }
};

export const getSpecialUser = (schoolid: string): string => {
  switch (schoolid) {
    case "SETEPEBARAPIRACA":
      return "/liveclasses";
    case "NOVASDEPAZ":
        return "/liveclasses";
    case "IBEM":
      return "/liveclasses";
    case "SETEPEB":
      return "/liveclasses";
    case "SELETIVO":
      return "/liveclasses";
    case "SEDI":
    case "Cursos":
      return "/courses";
    default:
      return "/";
  }
};
