import styled, { css } from "styled-components";

interface CourseContainerProps {
  type?: string;
  show?: boolean;
}

export const FlexContainer = styled.div`
  a {
    text-decoration: none;
  }
`;

export const Container = styled.div<CourseContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 20px;
  border-radius: 4px;
  transition: width 0.4s, height 0.4s;
  margin-top: 0px;
  border-radius: 5px;
  overflow: hidden;
  background: #0f0f0f;

  ${(props) =>
    props.type === "horizontal" &&
    css`
      width: 260px;
      height: auto;
    `}

  ${(props) =>
    props.type === "vertical" &&
    css`
      width: 150px;
      height: 100%;
    `}

  ${(props) =>
    props.type === "category" &&
    css`
      margin: 0;
      width: 120px;
      height: 160px;
    `}

  img {
    object-fit: cover;
    border-radius: 5px;

    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    transition: transform 0.8s, width 0.4s, height 0.4s;
    padding: 0;
    margin: 0;

    ${(props) =>
      props.type === "horizontal" &&
      css`
        width: 100%;
        height: 130px;
      `}

    ${(props) =>
      props.type === "vertical" &&
      css`
        width: 100%;
        min-height: 150px;
        height: 100%;
      `}

    ${(props) =>
      props.type === "category" &&
      css`
        width: 120px;
        height: 160px;
      `}
  }

  p:first-of-type {
    color: #fff;
    font-size: 14px;
    font-family: "Roboto Medium", sans-serif;
    width: 80%;

    margin-top: 8px;

    @media (max-width: 980px) {
      font-size: 1rem;
      text-align: center;
    }
  }

  @media (max-width: 980px) {
    margin-right: 10px;
  }
`;

export const WrapImgContainer = styled.figure`
  width: 100%;
  height: 185px;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
  flex-basis: min-content;
  cursor: pointer;

  > svg {
    color: #fff;
    border: 2px solid #fff;
    border-radius: 50%;
    font-size: 46px;
    padding: 5px;
    width: 35px;
    height: 35px;

    &:hover {
      background-color: #fff;
      color: #000;
      border: 2px solid #000;
    }
  }

  > p {
    width: auto !important;
    min-width: 55px;
  }
`;

export const ContentCardWrap = styled.div<CourseContainerProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  opacity: 1;
  z-index: 2;
  transition: all 0.2s ease;
  padding: 15px 10px;
  background: rgb(24, 24, 24);
  height: auto;

  ${(props) =>
    props.show === false &&
    css`
      background: transparent;
      opacity: 0;
      z-index: -1;
      height: 0;
    `}

  strong.colored {
    color: #fed44a;
  }

  div {
    &.card_button {
      p {
        color: #fed44a;
        text-align: center;
      }

      > button {
        margin: 0;
        width: auto;
        height: auto;
        opacity: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 20px;

        > svg {
          margin-right: 5px;
          font-size: 18px;
        }
      }
    }
  }
`;

export const PreContentCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 980px) {
    flex-direction: column;
  }

  p {
    margin: 5px 0 0 0;
    font-family: "Roboto Regular", sans-serif;
    color: #fff;
    width: auto !important;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;

    &.colored {
      color: #fed44a;
    }

    strong.colored {
      color: #fed44a;
    }

    @media (max-width: 980px) {
      font-size: 1rem;
      text-align: center;
    }
  }
`;
