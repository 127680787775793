/* eslint-disable react/no-array-index-key */
import React, { useState, useCallback, useEffect, useRef } from "react";
import { IoPaperPlaneOutline, IoStatsChart } from "react-icons/io5";

import { Exercise } from "../../../../models/CourseModels";

import { useToast } from "../../../../hooks/toast";
import { useAuth } from "../../../../hooks/auth";
import { useParams } from "react-router-dom";

import Checkbox from "../../Checkbox";
import Button from "../../Button";
import Loading from "../../Loading";

import { Container, Header, Item, HeaderItem, IMG } from "./styles";
import api from "../../../../services/api";
import apiV2 from "../../../../services/apiV2";

interface VideoModalProps {
  // actualTime?: {
  //   loaded: number;
  //   loadedSeconds: number;
  //   played: number;
  //   playedSeconds: number;
  // };
  actualTime?: any;
  quiz: Exercise;
  onAction(from: string): void;
  currentlyCourse?: any;
  queryParams?: any;
}

interface Resposta {
  id: number;
  answer: string;
  exerciseid: string;
}

interface Params {
  courseid: string;
  seasonid: string;
  movieid: string;
}

const VideoModal: React.FC<VideoModalProps> = ({
  actualTime,
  currentlyCourse,
  quiz,
  onAction,
  queryParams,
}) => {
  const { user } = useAuth();
  const { addToast } = useToast();
  const params = useParams();

  const { seasonid, courseid, movieid } = params as Params;
  const [selectedOption, setSelectedOption] = useState(-1);
  // const [canModalOpen, setCanModalOpen] = useState(true);
  const [questions, setQuestions] = useState<Exercise[]>([]);
  const [indexQ, setIndexQ] = useState(0);
  const [options, setOptions] = useState([]);
  const [finish, setFinish] = useState(false);
  const [apiOk, setApiOk] = useState(false);
  const arraySub = useRef<Resposta[]>([]);
  // array to diff local item with itens added start
  const [diffAnswersQty, setDiffAnswersQty] = useState<any>([]);
  // array to diff local item with itens added end
  const [finishO, setFinishO] = useState(false);
  const [indexAnswers, setIndexAnswers] = useState(false);
  const [retryQuiz, setRetryQuiz] = useState<boolean>(false);
  const [isInitialRender, setIsInitialRender] = useState(true);
  const [title, setTitle] = useState("");
  const [stats, setStats] = useState(false);
  const [acerto, setAcerto] = useState(0);
  const [currentlyVideo, setCurrentlyVideo] = useState<any>([]);
  const [quizComplete, setQuizComplete] = useState<any>(false);
  const [isLogging, setIsLogging] = useState(false);

  let param_storage_item = `${user.userid}_${courseid}_${queryParams?.season}_${queryParams?.movie}`;
  let verifyQuiz = localStorage.getItem(
    "@NextLevel:already-answers-" + param_storage_item
  );

  const getQuizQuestions = useCallback(
    async (retry: boolean = false) => {
      await apiV2
        .get<Exercise[]>(
          `/course/season/movie/exercise?courseid=${courseid}&seasonid=${queryParams?.season}&movieid=${queryParams.movie}&retry=${retry}&userid=${user.userid}`
        )
        .then((response) => {
          setQuestions(response.data);

          let finArray = JSON.parse(
            localStorage.getItem("@NextLevel:answers-" + param_storage_item) ||
              "{}"
          );
          Object.keys(finArray).map((key) => {
            arraySub.current[Number(key)] = finArray[key];
            return [finArray[key]];
          });

          setApiOk(true);
        });
    },
    [
      user,
      courseid,
      seasonid,
      movieid,
      retryQuiz,
      questions,
      questions.length,
      actualTime,
      queryParams,
    ]
  );

  const handlePostAnswers = useCallback(async () => {
    setIsLogging(true);
    let finalArray =
      localStorage.getItem("@NextLevel:answers-" + param_storage_item) || "{}";

    const body = {
      courseid: courseid,
      seasonid: queryParams?.season,
      movieid: queryParams?.movie,
      userid: user.userid,
      exercisestatus: finalArray,
    };

    await api
      .post("/course/season/movie/user/exercise", body)
      .catch((error) => {
        addToast({
          title: "Erro",
          description: "Erro ao enviar, tente novamente",
          type: "error",
        });
        console.log(error);
      })
      .finally(() => {
        setIndexAnswers(true);
        setFinishO(true);
        setIsLogging(false);
      });
  }, [user, courseid, movieid, movieid, queryParams]);

  const handleFinish = useCallback(() => {
    const useLocalStorageAnswers = localStorage.getItem(
      "@NextLevel:answers-" + param_storage_item
    );
    const answersLocalStorage =
      useLocalStorageAnswers !== null ? JSON.parse(useLocalStorageAnswers) : [];

    let isItemNull = answersLocalStorage.filter((item: any) => item === null);

    if (questions.length !== 0 && isItemNull.length > 0) {
      addToast({
        title: "Atenção",
        description: "Você precisa responder a todas as perguntas anteriores!",
        type: "error",
      });
      setSelectedOption(-1);

      return;
    }

    let finalArray = JSON.parse(
      localStorage.getItem("@NextLevel:answers-" + param_storage_item) || "{}"
    );

    finalArray.map((item: null, index: number) => {
      if (item === null) {
        arraySub.current[index] = { id: index, answer: "", exerciseid: "" };

        if (questions[index].answer === arraySub.current[index].answer)
          setAcerto(acerto + 1);
      }

      return arraySub.current;
    });

    localStorage.setItem(
      "@NextLevel:answers-" + param_storage_item,
      JSON.stringify(arraySub.current)
    );

    localStorage.setItem(
      "@NextLevel:already-answers-" + param_storage_item,
      JSON.stringify("true")
    );

    setQuizComplete(true);
    setSelectedOption(-1);
    handlePostAnswers();
  }, [
    user,
    indexQ,
    movieid,
    options,
    arraySub,
    selectedOption,
    questions.length,
    addToast,
    onAction,
    setDiffAnswersQty,
    setRetryQuiz,
    queryParams,
  ]);

  const handleQuizAction = useCallback(
    (action: string) => {
      if (selectedOption < 0) {
        addToast({
          title: "Atenção",
          description: "Para continuar é preciso marcar uma opção",
          type: "info",
        });

        return;
      }

      let resposta = {
        id: indexQ,
        answer:
          options[selectedOption] !== undefined
            ? options[selectedOption]
            : arraySub.current[indexQ]?.answer,
        exerciseid: questions[indexQ].exerciseid,
      };

      arraySub.current[indexQ] = resposta;

      localStorage.setItem(
        "@NextLevel:answers-" + param_storage_item,
        JSON.stringify(arraySub.current)
      );

      const addDiffAnswers = () => {
        let prevAnswers = diffAnswersQty;
        const found = prevAnswers.some((el: any) => el.id === indexQ);
        if (!found) setDiffAnswersQty((current: any) => [...current, resposta]);
      };

      addDiffAnswers();

      if (action !== "submit") {
        addToast({
          title: "Anotado",
          description: "Sua resposta foi salva",
          type: "success",
        });

        setIndexQ(indexQ + 1);
        setSelectedOption(-1);
      } else {
        handleFinish();
      }
    },
    [
      user,
      selectedOption,
      indexQ,
      options,
      movieid,
      diffAnswersQty,
      diffAnswersQty.length,
      addToast,
      onAction,
      getQuizQuestions,
      setDiffAnswersQty,
      queryParams,
    ]
  );

  const Verify = useCallback(() => {
    if (indexAnswers) {
      let contador = 0;
      questions.map((item, index) => {
        if (item?.answer === arraySub.current[index]?.answer)
          contador = contador + 1;
      });
      setAcerto(contador);
    }
  }, [acerto, indexAnswers, questions]);

  const removeItemDiffArray = (itemId: number) => {
    arraySub.current[itemId] = { id: itemId, answer: "", exerciseid: "" };
  };

  const handleRetryQuiz = useCallback(
    (retryStatus: boolean) => {
      localStorage.removeItem("@NextLevel:answers-" + param_storage_item);
      localStorage.removeItem(
        "@NextLevel:already-answers-" + param_storage_item
      );

      while (arraySub.current.length > 0) {
        arraySub.current.pop();
      }

      setStats(false);
      setIndexAnswers(false);
      setIndexQ(0);
      setQuestions([]);
      setOptions([]);
      setFinish(false);
      setApiOk(false);
      setDiffAnswersQty([]);
      setFinishO(false);
      setAcerto(0);
      setQuizComplete(false);
      getQuizQuestions(retryStatus);
    },
    [
      setStats,
      setIndexAnswers,
      setIndexQ,
      setQuestions,
      setOptions,
      setFinish,
      setApiOk,
      setDiffAnswersQty,
      setFinishO,
      setAcerto,
      setQuizComplete,
      getQuizQuestions,
    ]
  );

  const styles = {
    selected: {
      background: "rgb(255, 238, 189)",
      color: "black",
    },
    selectednow: {
      background: "rgb(242, 205, 98)",
      color: "black",
    },
    notselected: {
      background: "rgb(170, 173, 183)",
      color: "black",
    },
    correct: {
      background: "rgb(2, 245, 51, 0.5)",
      color: "white",
    },
    wrong: {
      background: "rgb(245, 66, 66, 0.4)",
      color: "white",
    },
    correctSelected: {
      background: "rgb(2, 245, 51, 0.5)",
      color: "white",
      borderBottom: "1.4px solid rgb(255, 255, 255)",
    },
    wrongSelected: {
      background: "rgb(245, 66, 66, 0.4)",
      color: "white",
      borderBottom: "1.4px solid rgb(255, 255, 255)",
    },
  } as const;

  useEffect(() => {
    if (isInitialRender) {
      setIsInitialRender(false);
      getQuizQuestions();
    }

    if (
      !retryQuiz &&
      verifyQuiz === null &&
      currentlyVideo?.courseseasonmovieuser &&
      currentlyVideo?.courseseasonmovieuser.exercisestatus !== " "
    ) {
      let targetContent = currentlyVideo.courseseasonmovieuser.exercisestatus;
      let alreadyAnswers = JSON.parse(targetContent);

      alreadyAnswers.forEach((answer: any, index: any) => {
        arraySub.current[index] = answer;
      });

      setIndexAnswers(true);
      setFinishO(true);
    }
    if (questions.length > 0 && quizComplete) {
      setIndexAnswers(true);
      setFinishO(true);
    }
    if (apiOk === true) {
      setOptions(JSON.parse(questions[indexQ].options));
      setTitle(questions[indexQ].question.replace(/<br\s*\/?>/gm, "\n"));
    }
    if (indexQ + 1 === questions.length) {
      setFinish(true);
    } else {
      setFinish(false);
    }
  }, [
    user,
    isInitialRender,
    apiOk,
    indexQ,
    questions,
    quizComplete,
    getQuizQuestions,
    currentlyCourse,
    queryParams,
  ]);

  useEffect(() => {
    if (currentlyCourse && currentlyCourse !== undefined) {
      currentlyCourse.filter((item: any) => {
        item.movieid === queryParams.movie && setCurrentlyVideo(item);
      });
    }
  }, [currentlyCourse]);

  useEffect(() => {
    // allow verify answers and keep it during component change between videoPaleyr and Quiz
    if (verifyQuiz && verifyQuiz !== undefined) {
      setQuizComplete(true);
    }
  }, []);

  useEffect(() => {
    if (arraySub && arraySub.current.length > 0) {
      const addDiffAnswersByAnswered = (item: any) => {
        let prevAnswers = diffAnswersQty;
        const found = prevAnswers.some((el: any) => el.id === item.id);
        if (!found) setDiffAnswersQty((current: any) => [...current, item]);
      };

      arraySub.current.map((item: any) => {
        item !== null && addDiffAnswersByAnswered(item);
      });
    }
  }, [arraySub, indexQ]);

  return (
    <Container>
      <div className="content-wrapper">
        <Header>
          <h4>Questões</h4>
          <div className="indexWrapper">
            {apiOk &&
              questions.map((item, index) =>
                indexAnswers ? (
                  index === indexQ ? (
                    item?.answer === arraySub.current[index]?.answer ? (
                      <HeaderItem
                        key={index}
                        style={styles.correctSelected}
                        onClick={() => {
                          setIndexQ(index);
                          setStats(false);
                          setSelectedOption(-1);
                        }}
                      >
                        <p>{index + 1}</p>
                      </HeaderItem>
                    ) : (
                      <HeaderItem
                        key={index}
                        style={styles.wrongSelected}
                        onClick={() => {
                          setIndexQ(index);
                          setStats(false);
                          setSelectedOption(-1);
                        }}
                      >
                        <p>{index + 1}</p>
                      </HeaderItem>
                    )
                  ) : item?.answer === arraySub.current[index]?.answer ? (
                    <HeaderItem
                      key={index}
                      style={styles.correct}
                      onClick={() => {
                        setIndexQ(index);
                        setStats(false);
                        setSelectedOption(-1);
                      }}
                    >
                      <p>{index + 1}</p>
                    </HeaderItem>
                  ) : (
                    <HeaderItem
                      key={index}
                      style={styles.wrong}
                      onClick={() => {
                        setIndexQ(index);
                        setStats(false);
                        setSelectedOption(-1);
                      }}
                    >
                      <p>{index + 1}</p>
                    </HeaderItem>
                  )
                ) : arraySub.current[index] ? (
                  index === indexQ ? (
                    <HeaderItem
                      key={index}
                      style={styles.selectednow}
                      onClick={() => {
                        setIndexQ(index);
                        setSelectedOption(-1);
                      }}
                    >
                      <p>{index + 1}</p>
                    </HeaderItem>
                  ) : (
                    <HeaderItem
                      key={index}
                      style={styles.selected}
                      onClick={() => {
                        setIndexQ(index);
                        setSelectedOption(-1);
                      }}
                    >
                      <p>{index + 1}</p>
                    </HeaderItem>
                  )
                ) : index === indexQ ? (
                  <HeaderItem
                    key={index}
                    style={styles.selectednow}
                    onClick={() => {
                      setIndexQ(index);
                      setSelectedOption(-1);
                    }}
                  >
                    <p>{index + 1}</p>
                  </HeaderItem>
                ) : (
                  <HeaderItem
                    key={index}
                    style={styles.notselected}
                    onClick={() => {
                      setIndexQ(index);
                      setSelectedOption(-1);
                    }}
                  >
                    <p>{index + 1}</p>
                  </HeaderItem>
                )
              )}
            {indexAnswers && (
              <HeaderItem
                style={styles.selectednow}
                onClick={() => {
                  setStats(true);
                  setSelectedOption(-1);
                  Verify();
                }}
              >
                <p>
                  <span className="stats">
                    <IoStatsChart />
                  </span>
                </p>
              </HeaderItem>
            )}
          </div>
        </Header>
        <div className="content hasVerticalScroll">
          {apiOk && !stats && (
            <div className="itemContent">
              <div className="title">
                <div className="finalTitle">
                  <h2>
                    {indexQ + 1} - {title}
                  </h2>
                </div>
              </div>
              <div className="finalDiv">
                <div className="finWrapper">
                  <div className="options">
                    <div className="column">
                      {finishO
                        ? options.map((item, index) =>
                            item == questions[indexQ]?.answer ? (
                              questions[indexQ]?.answer ==
                              arraySub.current[indexQ]?.answer ? (
                                <Item
                                  id={item}
                                  key={index}
                                  onClick={() =>
                                    setSelectedOption(selectedOption)
                                  }
                                >
                                  <div className="correct">
                                    <Checkbox
                                      customColor="#000"
                                      round
                                      isSelected={
                                        questions[indexQ]?.answer ==
                                        arraySub.current[indexQ]?.answer
                                      }
                                    />
                                    <p>{item}</p>
                                  </div>
                                </Item>
                              ) : arraySub.current[indexQ]?.answer !== "" ? (
                                <Item
                                  id={item}
                                  key={index}
                                  onClick={() => setSelectedOption(-1)}
                                >
                                  <div className="correct">
                                    <Checkbox
                                      customColor="#000"
                                      round
                                      isSelected={false}
                                    />
                                    <p>{item}</p>
                                  </div>
                                </Item>
                              ) : (
                                <Item
                                  id={item}
                                  key={index}
                                  onClick={() => setSelectedOption(-1)}
                                >
                                  <div className="unmarked">
                                    <Checkbox
                                      customColor="#000"
                                      round
                                      isSelected={false}
                                    />
                                    <p>{item}</p>
                                  </div>
                                </Item>
                              )
                            ) : item == arraySub.current[indexQ]?.answer ? (
                              <Item
                                id={item}
                                key={index}
                                onClick={() =>
                                  setSelectedOption(selectedOption)
                                }
                              >
                                <div className="wrong">
                                  <Checkbox
                                    customColor="#000"
                                    round
                                    isSelected={true}
                                  />
                                  <p>{item}</p>
                                </div>
                              </Item>
                            ) : (
                              <Item
                                id={item}
                                key={index}
                                onClick={() => setSelectedOption(-1)}
                              >
                                <div className="unmarked">
                                  <Checkbox
                                    customColor="#000"
                                    round
                                    isSelected={false}
                                  />
                                  <p>{item}</p>
                                </div>
                              </Item>
                            )
                          )
                        : arraySub.current.length > 0
                        ? options.map((item, index) =>
                            item == arraySub.current[indexQ]?.answer ? (
                              <Item
                                id={item}
                                key={index}
                                onClick={() => setSelectedOption(index)}
                              >
                                <Checkbox
                                  customColor="#000"
                                  round
                                  isSelected={true}
                                />
                                <p>{item}</p>
                              </Item>
                            ) : (
                              <Item
                                id={item}
                                key={index}
                                onClick={() => {
                                  removeItemDiffArray(indexQ);
                                  setSelectedOption(index);
                                }}
                              >
                                <Checkbox
                                  customColor="#000"
                                  round
                                  isSelected={selectedOption === index}
                                />
                                <p>{item}</p>
                              </Item>
                            )
                          )
                        : options.map((item, index) => (
                            <Item
                              id={item}
                              key={index}
                              onClick={() => setSelectedOption(index)}
                            >
                              <Checkbox
                                customColor="#000"
                                round
                                isSelected={selectedOption === index}
                              />
                              <p>{item}</p>
                            </Item>
                          ))}
                    </div>
                  </div>
                  {questions[indexQ].url !== " " && (
                    <a href={questions[indexQ].url} target="_blank">
                      <IMG>
                        <img src={questions[indexQ].url} />
                      </IMG>
                    </a>
                  )}
                </div>
              </div>
              <div className="answer">
                {finishO && indexAnswers ? (
                  <></>
                ) : !finish ? (
                  <Button
                    onClick={() => {
                      handleQuizAction("continue");
                    }}
                  >
                    <IoPaperPlaneOutline />
                    Responder
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      handleQuizAction("submit");
                    }}
                  >
                    <IoPaperPlaneOutline />
                    {isLogging ? <Loading size={2} /> : "FINALIZAR"}
                  </Button>
                )}
              </div>
            </div>
          )}
          {stats && (
            <div className="itemContent">
              <div className="title">
                <div className="finalTitle">
                  <h2>Estatísticas</h2>
                </div>
              </div>
              <div className="finalDiv">
                <div className="finWrapper">
                  <h2>
                    Você acertou {acerto} {acerto > 1 ? "questões" : "questão"}{" "}
                    de {questions.length}{" "}
                    {questions.length > 1 ? "questões" : "questão"}
                  </h2>
                </div>
                <div className="finWrapper">
                  {acerto <= Math.round(questions.length / 2) && (
                    <Button
                      onClick={() => {
                        setRetryQuiz(true);
                        handleRetryQuiz(true);
                      }}
                    >
                      <IoPaperPlaneOutline />
                      {isLogging ? <Loading size={2} /> : "Tentar Novamente"}
                    </Button>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Container>
  );
};

export default VideoModal;
