import { Thead, Tr } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

import {
  PaymentMethod,
  PaymentModel,
} from "../../../../../models/PaymentModels";
import {
  Table,
  Tbody,
  PageCount,
  Th,
  Td,
} from "../../../../_layout/AdminLayout.styles";

import StatusBadge from "../StatusBadge";

export const methodLabel: { [key in PaymentMethod]: string } = {
  credit_card: "Cartão de Crédito",
  free: "Grátis",
  pix: "Pix",
};

interface IPaymentsTable {
  data: PaymentModel[];
  currentIndex: number;
  lastIndex: number;
  onSelectItem: (args: { cartid: string; transactionid: string }) => void;
}

const PaymentsTable: React.FC<IPaymentsTable> = ({
  data,
  currentIndex,
  lastIndex,
  onSelectItem,
}) => {
  return (
    <>
      <Table breakpoint={1000}>
        <Thead>
          <Tr>
            <Th>Usuário</Th>
            <Th>Data</Th>
            <Th>Método de Pagamento</Th>
            <Th>Status</Th>
            <Th>Preço</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((item) => (
            <Tr
              key={item.transactionid}
              onClick={() =>
                onSelectItem({
                  cartid: item.cartid,
                  transactionid: item.transactionid,
                })
              }
            >
              <Td>{item.user_info.fullname}</Td>
              <Td>{item.date}</Td>
              <Td>{methodLabel[item.method]}</Td>
              <Td>
                <StatusBadge type={item.status} />
              </Td>
              <Td>R$ {(item.amount / 100).toFixed(2)}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <PageCount>
        Pagina {currentIndex} de {lastIndex}
      </PageCount>
    </>
  );
};

export default PaymentsTable;
