import React, { useMemo } from "react";
import { Switch, Route as Router, Redirect } from "react-router-dom";

import { useInitialRoute } from "../hooks/InitialRouteContext";
import { useAuth } from "../hooks/auth";
import { getUserRole } from "../utils/accessRoleHelper";

import Route from "./Route";
// Public pages
import Home from "../pages/PublicPages/Home";
import Login from "../pages/PublicPages/Login";
import SignUp from "../pages/PublicPages/SignUp";
import CourseFree from "../pages/PublicPages/Course";
import SeeItemsCategory from "../pages/PublicPages/SeeItemsCategory";
import Password from "../pages/PublicPages/Password";
import VerifyAccount from "../pages/PublicPages/VerifyAccount";
import LandingPage from "../pages/PublicPages/Landing";
// Private pages
import UserProfile from "../pages/PrivatePages/UserProfile";
import Courses from "../pages/PrivatePages/Courses";
import Certificado from "../pages/PrivatePages/Certificado";
import LiveClasses from "../pages/PrivatePages/LiveClasses";
import Course from "../pages/PrivatePages/Course";
import RecordedClasses from "../pages/PrivatePages/RecordedClasses";
import Users from "../pages/PrivatePages/Users";
//Admin routes
import Admin from "../pages/PrivatePages/Admin";
import AdminCourses from "../pages/PrivatePages/AdminCourses";
import AdminBanners from "../pages/PrivatePages/AdminBanners";
import AdminSections from "../pages/PrivatePages/AdminSections";

const NotFound = () => {
  const style = {
    minHeight: "100vh",
    width: "100%",
    margin: "10rem auto",
    display: "flex",
    justifyContent: "center",
  };
  return <h1 style={style}>Página não encontrada :(</h1>;
};

const Routes: React.FC = () => {
  const { user } = useAuth();

  const role = useMemo(() => {
    let getAllTabs = getUserRole(
      user?.profileid,
      user?.levelid,
      user?.schoolid
    );
    return getAllTabs;
  }, [user]);

  const { hasHome, isBindemy } = useInitialRoute();

  return (
    <>
      <Switch>
        {/* NOT PRIVATE  */}
        {hasHome && isBindemy ? (
          <Route path="/" exact component={LandingPage} />
        ) : hasHome ? (
          <Route path="/" exact component={Home} />
        ) : (
          <Router path="/" exact component={Home}>
            {hasHome ? <Home /> : <Redirect to="/login" exact />}
          </Router>
        )}

        <Route path="/course/certificado" exact component={Certificado}/>

        <Route path="/bindemy" exact component={LandingPage} />
        <Route path="/login" exact component={Login} />
        <Route path="/signup" exact component={SignUp} />
        <Route path="/course/gratis/:courseid" component={CourseFree} />
        <Route
          path="/categorycourses/:categoryid"
          component={SeeItemsCategory}
        />
        <Route path="/password/:type" component={Password} />
        <Route path="/password" component={Password} />
        <Route path="/verify" component={VerifyAccount} />
        {/* PRIVATE ROUTES */}
        <Route
          path="/profile"
          component={UserProfile}
          isPrivate
          cantAccess={role === "noCourses"}
        />
        <Route
          path="/courses"
          component={Courses}
          isPrivate
          cantAccess={role === "onlyLiveClasses"}
        />
        <Route
          path="/course/:courseid/:seasonid/:movieid"
          component={Course}
          isPrivate
          cantAccess={role === "noCourses"}
        />
        <Route
          path="/course/:courseid"
          component={Course}
          isPrivate
          cantAccess={role === "noCourses"}
        />
        <Route
          path="/liveclasses"
          component={LiveClasses}
          isPrivate
          cantAccess={role === "noCourses"}
        />
        <Route
          path="/liveclasses/:title"
          component={LiveClasses}
          isPrivate
          cantAccess={role === "noCourses"}
        />
        <Route
          path="/recordedclasses"
          component={RecordedClasses}
          isPrivate
          cantAccess={role === "noCourses"}
        />
        <Route
          path="/admin"
          component={Admin}
          isPrivate
          cantAccess={user?.levelid === "Admin"}
          exact
        />
        <Route
          path="/admin/users"
          component={Users}
          isPrivate
          cantAccess={user?.levelid === "Admin"}
          exact
        />
        <Route
          path="/admin/courses"
          component={AdminCourses}
          isPrivate
          cantAccess={user?.levelid === "Admin"}
          exact
        />
        <Route
          path="/admin/banners"
          component={AdminBanners}
          isPrivate
          cantAccess={user?.levelid === "Admin"}
          exact
        />
        <Route
          path="/admin/sections"
          component={AdminSections}
          isPrivate
          cantAccess={user?.levelid === "Admin"}
          exact
        />
        <Route
          path="/admin/add-course-video"
          component={AdminCourses}
          isPrivate
          cantAccess={user?.levelid === "Admin"}
          exact
        />
        <Route path="*" component={NotFound} />
      </Switch>
    </>
  );
};

export default Routes;
