import { MdExitToApp, MdShoppingCart } from "react-icons/md";
import { useAuth } from "../../../hooks/auth";
import Sidebar from "react-sidebar";

import {
  Container,
  SidebarMenu,
  Title,
  Link,
  Separator,
  HeaderContainer,
  Burguer,
  Wrapper,
  StylesWrapper,
} from "./styles";
import { useEffect, useState } from "react";
import { FaUsers, FaVideo, FaImage, FaListUl } from "react-icons/fa";
import { AiOutlineVideoCameraAdd } from "react-icons/ai";
import { useInitialRoute } from "../../../hooks/InitialRouteContext";

const mql = window.matchMedia(`(min-width: 900px)`);

interface IAdminHeader {
  children: JSX.Element;
}

const AdminHeader: React.FC<IAdminHeader> = ({ children }) => {
  const { signOut } = useAuth();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [dropdown, setDropdown] = useState();
  const [expanded, setExpanded] = useState(false);
  const [sidebarDocked, setSidebarDocked] = useState(mql.matches);

  const { subdomainUrlImg } = useInitialRoute();

  const handleToggleSideBar = (open: boolean) => {
    setSidebarOpen(open);
  };

  const closeNav = () => {
    document.getElementById("mySidebar")!.style.width = "0";
    document.getElementById("body-container")!.style.marginLeft = "0";
  };

  function mediaQueryChanged() {
    setSidebarDocked(mql.matches);
    setSidebarOpen(false);
  }

  useEffect(() => {
    mql.addListener(mediaQueryChanged);

    return () => {
      mql.removeListener(mediaQueryChanged);
    };
  });

  return (
    <StylesWrapper
      expanded={!sidebarDocked || open || expanded}
      onMouseLeave={() => setExpanded(false)}
    >
      <Sidebar
        sidebar={
          <SidebarMenu>
            <Title>
              <img alt="Logo" src={subdomainUrlImg} />
              <span>Administração</span>
            </Title>

            <Link
              exact
              activeClassName="selected"
              to="/admin"
              onClick={() => handleToggleSideBar(false)}
            >
              <MdShoppingCart />
              <span>Minhas Vendas </span>
            </Link>

            <Link
              activeClassName="selected"
              to="/admin/users"
              onClick={() => handleToggleSideBar(false)}
            >
              <FaUsers />
              <span>Usuários</span>
            </Link>

            <Link
              activeClassName="selected"
              to="/admin/banners"
              onClick={() => handleToggleSideBar(false)}
            >
              <FaImage />
              <span>Banners</span>
            </Link>

            <Link
              activeClassName="selected"
              to="/admin/sections"
              onClick={() => handleToggleSideBar(false)}
            >
              <FaListUl />
              <span>Sessões</span>
            </Link>

            <Link
              activeClassName="selected"
              to="/admin/courses"
              onClick={() => handleToggleSideBar(false)}
            >
              <FaVideo />
              <span>Meus Cursos</span>
            </Link>

            <Link
              activeClassName="selected"
              to="/admin/add-course-video"
              onClick={() => handleToggleSideBar(false)}
            >
              <AiOutlineVideoCameraAdd />
              <span>Adicionar Videos</span>
            </Link>
            <Separator />
            <Link exact to="/" onClick={signOut}>
              <MdExitToApp />
              <span>Sair</span>
            </Link>
          </SidebarMenu>
        }
        open={sidebarOpen}
        docked={sidebarDocked}
        touchHandleWidth={0}
        onSetOpen={handleToggleSideBar}
        styles={{
          sidebar: {
            background: "#373640",
            zIndex: "3",
            overflowX: "hidden",
            display: "flex",
          },
        }}
        sidebarClassName="sidebar"
      >
        <Container
          onMouseEnter={() => setExpanded(false)}
          onMouseLeave={() => setExpanded(true)}
        >
          <HeaderContainer mobile={!sidebarDocked}>
            {!sidebarDocked && (
              <Burguer
                onClick={() => {
                  handleToggleSideBar(!open);
                }}
              >
                <div />
                <div />
                <div />
              </Burguer>
            )}
          </HeaderContainer>
          <Wrapper>{children}</Wrapper>
        </Container>
      </Sidebar>
    </StylesWrapper>
  );
};

export default AdminHeader;
