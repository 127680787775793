import React, { useCallback, useEffect, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { FiChevronRight } from "react-icons/fi";

import { CourseSeasonMovie } from "../../../models/CourseModels";

import { useProgress } from "../../../hooks/progress";

import ExercisePreviewCard from "../ExercisePreviewCard";
import ProgressBar from "../ProgressBar";

import hasNotesIcon from "../../../assets/icons/hasNotes.png";

import {
  Container,
  VideoCardWrapper,
  SelectedIconContainer,
  Thumb,
  AnnotationIndicator,
  Time,
  StyledProgressBar,
  VideoInfo,
} from "./styles";

interface Params {
  courseid: string;
  movieid: string;
}

interface VideoCardProps {
  isWatching?: boolean;
  alreadyWatched?: boolean;
  exercisePreviewActive?: boolean;
  video: CourseSeasonMovie;
  index?: number;
  selectedPosition: number;
  onSelect(position: number): void;
  onOpen: () => void;
}

const VideoCard = ({
  isWatching = false,
  alreadyWatched = false,
  exercisePreviewActive = false,
  video,
  index,
  selectedPosition,
  onSelect,
}: VideoCardProps) => {
  const { videos } = useProgress();
  const { push } = useHistory();
  const { courseid, movieid } = useParams() as Params;

  const handleOpenExercise = useCallback(() => {
    onSelect(video.position);
    push(
      `?exercise=true&course=${video.courseid}&season=${video.seasonid}&movie=${video.movieid}`
    );
    // push(`/course/${video.courseid}?exercise=true`);
  }, [push, video, onSelect]);

  // essa função atualiza o marcador da posição do video
  const videoProgress = useMemo(() => {
    const videoDuration = video.videoduration; // retorna a duração total do video
    // console.log("data", videos[video.url]);

    let progress = 0;

    if (video) {
      let timeWatched = "";
      if (
        video.courseseasonmovieuser &&
        video.courseseasonmovieuser.videowatched
      ) {
        timeWatched = video.courseseasonmovieuser.videowatched;
      } else {
        timeWatched = videos[video.url] ? videos[video.url] : "00:00:00";
      }
      let vdHours;
      let vdMinutes;
      let vdSeconds;
      let totalSeconds;
      if (Number(timeWatched.split(":")[2]) > 0) {
        timeWatched = videos[video.url];
      }
      const [twHours, twMinutes, twSeconds] = timeWatched.split(":");
      const secondsWatched =
        Number(twHours) * 60 * 60 + Number(twMinutes) * 60 + Number(twSeconds);
      if (videoDuration.split(":").length > 2) {
        [vdHours, vdMinutes, vdSeconds] = videoDuration.split(":");
        totalSeconds =
          Number(vdHours) * 60 * 60 +
          Number(vdMinutes) * 60 +
          Number(vdSeconds);
      } else {
        [vdMinutes, vdSeconds] = videoDuration.split(":");
        totalSeconds = Number(vdMinutes) * 60 + Number(vdSeconds);
      }
      progress = Math.round((secondsWatched * 100) / totalSeconds);
      return progress;
    }
    return progress;
  }, [videos[video.url]]);

  useEffect(() => {
    if (courseid && movieid) {
      onSelect(video.position);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <VideoCardWrapper
        onClick={() => {
          onSelect(video.position);
          if (window.location.pathname.includes("gratis")) {
            push(`/course/gratis/${video.courseid}`);
          } else {
            push(`/course/${video.courseid}`);
          }
        }}
      >
        <SelectedIconContainer
          isWatching={
            isWatching &&
            !(video.courseid === courseid && video.movieid === movieid)
          }
        >
          <FiChevronRight size={22} />
        </SelectedIconContainer>
        <Thumb>
          {video.notes.length > 0 && (
            <AnnotationIndicator>
              <img src={hasNotesIcon} alt="has-notes-icon" />
            </AnnotationIndicator>
          )}
          <img className="video-thumb" src={video.thumb} alt={video.movieid} />
          <Time>
            <p>{video.videoduration}</p>
          </Time>
          <StyledProgressBar>
            <ProgressBar at={videoProgress} customHeight={4} />
          </StyledProgressBar>
        </Thumb>
        <VideoInfo>
          {index ? (
            <h3>{`${index}. ${video.title}`}</h3>
          ) : (
            <h3>{`${video.title}`}</h3>
          )}
          <p>{video.description}</p>
        </VideoInfo>
      </VideoCardWrapper>
      {exercisePreviewActive && (
        <ExercisePreviewCard
          description={video.exerciseshortmessage}
          onOpen={handleOpenExercise}
          isWatchingExercise={
            video.position === selectedPosition &&
            video.courseid === courseid &&
            video.movieid === movieid
          }
        />
      )}
    </Container>
  );
};

export default VideoCard;
