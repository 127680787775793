import React, { Dispatch, RefObject, SetStateAction, useEffect } from "react";
import { useParams } from "react-router-dom";

import {
  CourseSeason,
  CourseSeasonMovie,
  Course as CourseModel,
} from "../../../models/CourseModels";
import { Notes } from "../../../models/AuthModels";

import VimeoComponent from "../../../components/Atoms/VimeoComponent/CourseVimeoComponent";
import AnnotationCard from "../../../components/Atoms/AnnotationCard";
import Modal from "../../../components/Mols/Modal";
import CourseSideMenu from "../../../components/Mols/SideMenus/CourseSideMenu";
import CertificateModal from "./Modals/CertificateModal";
import ModalCertificate from "../../../components/Mols/Modal/Certificate";

import AnnotationModal from "./Modals/AnnotationModal";

import {
  Container,
  Content,
  VideoContainer,
  AnnotationsContainer,
  AddNoteWrapper,
  StyledButton,
  NotesWrapper,
  StyledSeparator,
} from "./styles";

interface Params {
  courseid: string;
  seasonid: string;
  movieid: string;
}

interface CourseDefaultViewProps {
  actualTime: {
    playedSeconds: number;
    played: number;
    loadedSeconds: number;
    loaded: number;
  };
  addNoteInputRef: RefObject<HTMLInputElement>;
  courseDetails: CourseModel | undefined;
  courseSeasons: Array<CourseSeason>;
  courseSeasonMovies: Array<CourseSeasonMovie>;
  filters: Array<string>;
  filteredCourseSeasonMovies: Array<CourseSeasonMovie>;
  isAddingNote: boolean;
  isLoading: boolean;
  isLoadingInfo: boolean;
  isNoteLoading: boolean;
  isPlaying: boolean;
  notes: Array<Notes>;
  selectedVideoPosition: number;
  showAddNote: boolean;
  showCertificate: boolean;
  setActualTime: Dispatch<SetStateAction<any>>;
  setIsPlaying: Dispatch<SetStateAction<boolean>>;
  setShowAddNote: Dispatch<SetStateAction<boolean>>;
  setShowCertificate: Dispatch<SetStateAction<boolean>>;
  actionsBeforeRequests(): void;
  getSeasonMovies(item: any): Promise<void>;
  handleAddNote(action: string): void;
  handleChangeVideo(videoPosition: any): void;
  handleDeleteNote?(noteId: string, index: number): Promise<void>;
  handleEditNote(text: string, index: number): Promise<void>;
  handleFilter(item: any): void;
  handleFinishVideo(info: any): void;
  handlePauseVideo(info: any): void;
}

const CourseDefaultView: React.FC<CourseDefaultViewProps> = ({
  actualTime,
  addNoteInputRef,
  courseDetails,
  courseSeasons,
  courseSeasonMovies,
  filters,
  filteredCourseSeasonMovies,
  isAddingNote,
  isLoading,
  isLoadingInfo,
  isPlaying,
  isNoteLoading,
  notes,
  selectedVideoPosition,
  showAddNote,
  showCertificate,
  setActualTime,
  setIsPlaying,
  setShowAddNote,
  setShowCertificate,
  actionsBeforeRequests,
  getSeasonMovies,
  handleAddNote,
  handleChangeVideo,
  handleDeleteNote,
  handleEditNote,
  handleFilter,
  handleFinishVideo,
  handlePauseVideo,
}) => {
  const params = useParams();

  const { movieid, seasonid } = params as Params;

  useEffect(() => {
    console.log(movieid);
    console.log(seasonid);
    console.log("public");
  });

  return (
    <Container>
      {showAddNote && (
        <Modal onClose={() => setShowAddNote(false)}>
          <AnnotationModal
            actualTime={actualTime}
            addNoteInputRef={addNoteInputRef}
            isAddingNote={isAddingNote}
            handleAddNote={handleAddNote}
            setIsPlaying={setIsPlaying}
            setShowAddNote={setShowAddNote}
          />
        </Modal>
      )}
      {showCertificate && (
                    <ModalCertificate onClose={() => setShowCertificate(false)}>
                     <CertificateModal
                                    setShowCertificate={setShowCertificate}
                                    courseDetails={courseDetails}
                                    />
                    </ModalCertificate>
                  )}
      <CourseSideMenu
        setShowCertificate={setShowCertificate}
        courseDetails={courseDetails}
        courseSeasonOptions={
          courseSeasons &&
          courseSeasons.map((courseSeason) => ({
            key: courseSeason.seasonid,
            value: courseSeason.title,
          }))
        }
        firstFilter={{ key: filters[0], value: filters[0] }}
        filters={filters}
        firstItem={
          courseSeasons[0] && {
            key: courseSeasons[0].seasonid,
            value: courseSeasons[0].title,
          }
        }
        videos={
          filteredCourseSeasonMovies.length < 1
            ? courseSeasonMovies && courseSeasonMovies
            : filteredCourseSeasonMovies
        }
        selectedPosition={selectedVideoPosition}
        isLoading={isLoading}
        isLoadingInfo={isLoadingInfo}
        onFilterChage={handleFilter}
        onSeasonChange={(item) => {
          actionsBeforeRequests();
          setTimeout(() => {
            getSeasonMovies(item);
          }, 200);
        }}
        onVideoChange={(e) => {
          actionsBeforeRequests();
          setTimeout(() => {
            handleChangeVideo(e);
          }, 200);
        }}
      />

      <StyledSeparator type="vertical" customHeight={100} />

      {/* parte central da tela */}
      <Content>
        {!movieid && !seasonid ? (
          <>
            <VideoContainer>
              {courseSeasonMovies.length > 0 && (
                <VimeoComponent
                  large={notes.length < 1}
                  url={
                    courseSeasonMovies[selectedVideoPosition] &&
                    courseSeasonMovies[selectedVideoPosition].url
                  }
                  video={courseSeasonMovies.find(
                    (video) => video.position === selectedVideoPosition && video
                  )}
                  // isLoading={isLoading}
                  isPlaying={isPlaying}
                  setIsPlaying={setIsPlaying}
                  actualTime={actualTime}
                  setActualTime={!showAddNote && setActualTime}
                  onPause={handlePauseVideo}
                  onFinish={handleFinishVideo}
                  quizzes={[]}
                />
              )}
            </VideoContainer>
          </>
        ) : (
          <>
            <p>QuestionContainer</p>
          </>
        )}
      </Content>
    </Container>
  );
};

export default CourseDefaultView;
