import React, { useState, useCallback, useEffect } from "react";
import { FiTrash } from "react-icons/fi";
import { useHistory } from "react-router-dom";

import {
  Course as CourseInterface,
  CourseSeason,
} from "../../../models/CourseModels";

import { convertNumberToBRL } from "../../../utils/functions";
import { useCart } from "../../../hooks/cart";
import api from "../../../services/api";

import { Modal } from "../Modal";
import CourseDetailsModal from "../CourseCardV2/Modal/CourseDetailsModal";

import {
  FlexContainer,
  Container,
  Thumb,
  Heading,
  StyledButton,
} from "./syles";

interface CourseCardProps {
  course: CourseInterface;
  isCartOpen: boolean;
}

const CartCourseCard: React.FC<CourseCardProps> = ({ course, isCartOpen }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [seasonListLength, setSeasonListLength] = useState(0);
  const [courseSeasons, setCourseSeasons] = useState<CourseSeason[]>([]);

  const { removeCourse, cartCourses, addCourse } = useCart();
  const { push } = useHistory();

  const isCourseInCart = (): boolean => {
    const alreadyInCart = cartCourses.courses.find(
      (i: any) => i.courseid === course.courseid
    );

    return !!alreadyInCart;
  };

  const courseNewPrice = useCallback((): any => {
    const newPrice = cartCourses.productcouponlist.find(
      (i: any) => i.product.courseid === course.courseid
    );
    if (newPrice) {
      return newPrice;
    }
  }, [cartCourses, course]);

  const getCourseSeasons = useCallback(async () => {
    if (courseSeasons.length < 1) {
      setCourseSeasons([]);
      const response = await api.get<CourseSeason[]>(
        `/course/season?courseid=${course.courseid}`
      );
      setSeasonListLength(response.data.length);
      setCourseSeasons(response.data);
    }
  }, [course.courseid, courseSeasons]);

  const addCourseToCart = useCallback(() => {
    if (course.is_owner) {
      push(`/course/${course.courseid}`);
    } else {
      addCourse(course);
    }
  }, [addCourse, course, push]);

  useEffect(() => {
    if (isCartOpen && courseSeasons.length < 1) {
      getCourseSeasons();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCartOpen]);

  return (
    <FlexContainer>
      <Modal isOpen={isModalOpen}>
        <CourseDetailsModal
          closeModal={() => setIsModalOpen(false)}
          course={course}
          courseSeasons={courseSeasons}
          addToCart={addCourseToCart}
          alreadyInCart={isCourseInCart()}
          seasonListLength={seasonListLength}
        />
      </Modal>
      <Container>
        <Thumb
          onClick={() => setIsModalOpen(true)}
          src={course.thumburl}
          alt={course.courseid}
        />
        <div className="courseContent">
          <Heading onClick={() => setIsModalOpen(true)}>
            {/* <p>{!!courseNewPrice() ? '' : course.title}</p> */}
            {courseNewPrice() && courseNewPrice().coupon ? (
              <div className="nameWithCoupon">
                <p>{course.title}</p>
                <strong>{`(${courseNewPrice().coupon.couponid})`}</strong>
              </div>
            ) : (
              <p>{course.title}</p>
            )}
            <span className="price-span green">
              {courseNewPrice() &&
              !!courseNewPrice().coupon &&
              typeof courseNewPrice().newpricewithcouponapplied === "number" ? (
                <>
                  <p className="oldPrice">
                    {convertNumberToBRL(course?.price ?? 0)}
                  </p>
                  <p>
                    {convertNumberToBRL(
                      courseNewPrice().newpricewithcouponapplied || 0
                    )}
                  </p>
                </>
              ) : (
                <p>{convertNumberToBRL(course?.price ?? 0)}</p>
              )}
            </span>
          </Heading>
          <div className="button">
            <StyledButton onClick={() => removeCourse(course)}>
              <p>Remover</p>
              <FiTrash size={18} />
            </StyledButton>
          </div>
        </div>
      </Container>
    </FlexContainer>
  );
};

export default CartCourseCard;
