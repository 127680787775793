import styled from "styled-components";
import Button from "../../../components/Atoms/Button";

export const Container = styled.div`
  width: 100vw;
  min-height: 100vh;
  padding: 2rem 0;
  /* margin-bottom: 10vh; */
`;

export const ContentWrapper = styled.main`
  width: 100%;
  min-height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const LoginWrapper = styled.div`
  height: 56%;
  width: 50%;

  display: flex;
  flex-direction: column;
  align-items: center;

  margin-bottom: 2rem;

  @media (max-width: 979px) {
    width: 100%;
    padding: 0 1rem;
  }

  h3 {
    font-size: 14px;
  }

  > img {
    height: 120px;
    margin-bottom: 4%;
    cursor: pointer;
  }

  form {
    width: 100%;
    max-width: 500px;
  }
`;

export const FormWrapper = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;

  justify-content: space-around;
  align-items: center;
  gap: 1rem;

  h3 {
    font-family: "Raleway", sans-serif;
    font-size: 24px;
    font-weight: lighter;
    color: #353536;
    letter-spacing: 4px;
  }

  .forgot {
    border: none;
    background: none;
    cursor: pointer;
  }

  .dropdown-wrapper {
    display: flex;
    width: 100%;

    margin-bottom: 8px;

    &.school-info {
      display: flex;
      align-items: center;
    }
  }
`;

export const StyledButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;

  &.signup {
    background: #fff;

    transition: filter 0.2s;
    &:hover {
      filter: brightness(0.6);
    }
  }
`;
