import React, { useCallback, useState, useRef, useEffect } from "react";
import { FiX, FiCreditCard, FiCheckCircle } from "react-icons/fi";
import { useHistory } from "react-router-dom";

import { useAuth } from "../../../hooks/auth";
import { useCart } from "../../../hooks/cart";
import { convertNumberToBRL } from "../../../utils/functions";

import pixIcon from "../../../assets/icons/pix.svg";

import CartCourseCard from "../../Atoms/CartCourseCard";
import Loading from "../../Atoms/Loading";
import { ToastContainer, toast } from "react-toastify";
import { AxiosResponse } from "axios";
import apiV2 from "../../../services/apiV2";
import Payment from "../Payment";

import {
  Container,
  ItensSection,
  TitleSection,
  CouponContainer,
  ButtonSection,
  StyledButton,
} from "./styles";

interface ShoppingCartProps {
  handleCart(): void;
  refProp?: any;
}

const ShoppingCart: React.FC<ShoppingCartProps> = ({ handleCart, refProp }) => {
  const [isBuying, setIsBuying] = useState(false);
  const [isPaying, setIsPaying] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [cartId, setCartId] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");

  const couponInputRef = useRef<HTMLInputElement>(null);

  const { user } = useAuth();
  const {
    cartCourses,
    generateKey,
    applyCoupon,
    isCartOpen,
    saveCart,
    savePayment,
    toggleCart,
    reset,
  } = useCart();
  const { push } = useHistory();

  const handleBuy = useCallback(async () => {
    setIsLoading(true);
    const generatedId = generateKey();
    setCartId(generatedId);

    const cartItensIds = cartCourses?.courses.map((item: any) => item.courseid);

    if (!user) {
      push("/login");
    }

    await apiV2.post<string>("/v2/cart", {
      userid: user.userid,
      cartid: generatedId,
      products: cartItensIds,
      coupons: cartCourses?.coupons,
      couponidtoapply: "",
    });
    setIsBuying(!isBuying);
    setIsLoading(false);
  }, [cartCourses, isBuying, user, push, generateKey]);

  const handleCreditCard = useCallback(async () => {
    setIsLoading(true);
    await saveCart(cartId);
    setIsLoading(false);
    setPaymentMethod("card");
    setIsPaying((state) => !state);
  }, [saveCart, cartId]);

  const handlePix = useCallback(async () => {
    setIsLoading(true);
    await saveCart(cartId);
    setIsLoading(false);
    setPaymentMethod("pix");
    setIsPaying((state) => !state);
  }, [saveCart, cartId]);

  const finishOrder = useCallback(async () => {
    const savePaymentBody = {
      userid: user.userid,
      cartid: cartCourses?.cartid,
      method: "free",
      amount: cartCourses?.total * 100,
      coupons: cartCourses?.coupons,
      couponid: cartCourses?.coupon?.couponid ?? "",
    };

    setIsLoading(true);
    const response = (await savePayment(
      savePaymentBody
    )) as AxiosResponse<string>;
    setIsLoading(false);

    if (response.data === "OK") {
      reset();
      generateKey(true);
      toast.success(
        "Seu pagamento foi confirmado e você receberá acesso aos cursos comprados.",
        {
          position: "top-left",
        }
      );
      toggleCart(false);
    } else if (response.data === "ERROR") {
      toast.error(
        "Ocorreu um erro na confirmaçao de seu pagamento! Por favor, tente novamente mais tarde.",
        {
          position: "top-left",
        }
      );
    }
  }, [user, savePayment, cartCourses, toggleCart, reset, generateKey]);

  const getButtonSection = (): JSX.Element => {
    if (user) {
      if (
        (cartCourses?.totalwithcouponapplied &&
          cartCourses?.totalwithcouponapplied === 0) ||
        cartCourses?.total === 0
      ) {
        return (
          <StyledButton
            onClick={finishOrder}
            enabled={cartCourses?.courses.length > 0 && !isLoading}
          >
            {isLoading ? <Loading size={1.6} /> : "Concluir Compra"}
          </StyledButton>
        );
      }

      if (isBuying) {
        return (
          <StyledButton
            onClick={handleBuy}
            enabled={cartCourses?.courses.length > 0 && !isLoading}
          >
            {isLoading ? <Loading size={1.6} /> : "Finalizar Compra"}
          </StyledButton>
        );
      }
      return (
        <>
          <StyledButton
            onClick={handleCreditCard}
            enabled={cartCourses?.courses.length > 0 || !isLoading}
            loading={isLoading}
          >
            <FiCreditCard size={16} />
            <p>Cartão de crédito</p>
          </StyledButton>
          <StyledButton
            onClick={handlePix}
            enabled={cartCourses?.courses.length > 0 || !isLoading}
            className="pix-btn"
            loading={isLoading}
          >
            <img src={pixIcon} alt="pix-icon" />
            <p>Pix</p>
          </StyledButton>
        </>
      );
    }
    return (
      <>
        <StyledButton
          onClick={() => {
            handleCart();
            push("/login");
          }}
          enabled={cartCourses?.courses.length > 0}
        >
          Login
        </StyledButton>
        <StyledButton
          onClick={() => push("/signup")}
          enabled={cartCourses?.courses.length > 0}
        >
          Cadastro
        </StyledButton>
      </>
    );
  };

  const handleApplyCoupon = async (): Promise<void> => {
    if (user) {
      setIsLoading(true);
      await applyCoupon(couponInputRef.current?.value ?? "");
      setIsLoading(false);
    } else {
      toast.warn("É preciso fazer login para aplicar um cupom", {
        position: "top-left",
      });
    }

    if (couponInputRef.current) {
      couponInputRef.current.value = "";
    }
  };

  useEffect(() => {
    const generatedId = generateKey();
    setCartId(generatedId);

    document.addEventListener("keydown", (e) => {
      if (e.key === "Escape" || e.key === "Esc") {
        const cartWrapper = document.getElementById("cart-container");

        if (cartWrapper && cartWrapper.classList.contains("active")) {
          toggleCart(false);
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCartOpen]);

  return (
    <Container
      ref={refProp}
      id="cart-container"
      className={isCartOpen ? "active" : ""}
    >
      {!isPaying && cartCourses?.courses ? (
        <>
          <TitleSection>
            <h2> Carrinho </h2>
            <FiX size={20} className="close_btn" onClick={handleCart} />
          </TitleSection>
          <ItensSection className="hasVerticalScroll">
            {cartCourses?.courses.map((course: any) => (
              <CartCourseCard
                key={course.courseid}
                course={course}
                isCartOpen={isCartOpen}
              />
            ))}
          </ItensSection>
          <ButtonSection>
            <CouponContainer>
              <p>Cupom de desconto: </p>
              <input
                type="text"
                name="coupon"
                id="coupon"
                ref={couponInputRef}
              />
              <StyledButton
                onClick={handleApplyCoupon}
                enabled={cartCourses?.courses.length > 0 || !isLoading}
                loading={isLoading}
                customStyle="success"
                className="apply-coupon-btn"
              >
                <p>Aplicar</p>
                <FiCheckCircle />
              </StyledButton>
              <ToastContainer />
            </CouponContainer>
            <div className="total">
              {cartCourses?.coupon ? (
                <p>
                  <strong>Total: </strong>
                  {`(${cartCourses?.coupon.couponid})`}
                </p>
              ) : (
                <p className="bold">Total:</p>
              )}

              {cartCourses?.coupon ? (
                <div>
                  <p className="oldPrice">
                    {convertNumberToBRL(cartCourses?.oldPrice ?? 0)}
                  </p>

                  <span className="price-span green">
                    {convertNumberToBRL(cartCourses?.total)}
                  </span>
                </div>
              ) : (
                <span className="price-span green">
                  {convertNumberToBRL(cartCourses?.total)}
                </span>
              )}
            </div>
            <div className="btns">{getButtonSection()}</div>
          </ButtonSection>
        </>
      ) : (
        <Payment
          method={paymentMethod}
          closeCart={handleCart}
          cartId={cartId}
          stepBack={() => setIsPaying(false)}
        />
      )}
    </Container>
  );
};

export default ShoppingCart;
