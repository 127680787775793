import React from "react";
import { FiArrowLeft } from "react-icons/fi";
import { useHistory } from "react-router-dom";

import { CourseSeasonMovie, Course } from "../../../../models/CourseModels";

import VideoCard from "../../../Atoms/VideoCard/CourseVideoCard";
import ShimmerVideoCard from "../../../Atoms/Shimmer/VideoCard";

import Dropdown from "../../../Atoms/Dropdown";

import Separator from "../../../Atoms/Separator";
import {
  CertificateWrapper,
  StyledButton,
  Container,
  Heading,
  Content,
  FilterContainer,
  VideosScrollContainer,
  ShimmerMovieBanner,
  ShimmerMovieTitle,
} from "./styles";

interface SelectItems {
  key: string;
  value: string;
}

interface CourseSideMenuProps {
  setShowCertificate(state: boolean): void;
  courseDetails?: Course;
  courseSeasonOptions?: Array<SelectItems>;
  filters?: Array<string>;
  firstFilter?: { key: string; value: string };
  firstItem?: SelectItems;
  isLoading?: boolean;
  isLoadingInfo?: boolean;
  videos?: Array<CourseSeasonMovie>;
  selectedPosition?: number;
  onSeasonChange?(item: any): void;
  onVideoChange?(position: number): void;
  onFilterChage(item: any): void;
}



const CourseSideMenu = ({
  setShowCertificate,
  courseDetails,
  courseSeasonOptions = [{ key: "", value: "" }],
  filters,
  firstFilter = { key: "", value: "" },
  firstItem = { key: "", value: "" },
  isLoading = false,
  isLoadingInfo = false,
  selectedPosition = 0,
  videos = [],
  onSeasonChange = () => console.log("default"),
  onVideoChange = () => console.log("default"),
  onFilterChage,
}: CourseSideMenuProps) => {
  const { push } = useHistory();

  const bla = () => { console.log("abriu")};

  return (
    <Container customType="">
      <Heading>
        <>
          {courseDetails && courseDetails.thumburl ? (
            <>
              <h3>
                {" "}
                <FiArrowLeft size={24} onClick={() => push("/courses")} />
                {courseDetails.title}
              </h3>
              <img src={courseDetails.thumburl} alt="thumb" />
            </>
          ) : (
            <ShimmerMovieBanner />
          )}
          {courseDetails ? (
            <p>{courseDetails?.description}</p>
          ) : (
            <ShimmerMovieTitle />
          )}

           {courseDetails && courseDetails.userprogress>=1 && (



 <button
                                                                           className="forgot"
                                                                           type="button"
                                                                           onClick={() => setShowCertificate(true)}
                                                                         >
                                                                           Certificado
                                                                         </button>


                                                                         )}

          <Separator customWidth={70} customColor="#ffd35c" type="horizontal" />
        </>
      </Heading>
      <Content>
        <FilterContainer>
          <>
            <p>Escolha</p>
            <div className="subject-select">
              <Dropdown
                arrowColor="#ffd35c"
                textColor="#ffd35c"
                title="Selecionar aula"
                items={courseSeasonOptions}
                defaultValue={isLoading ? { key: "", value: "" } : firstItem}
                isLoading={videos.length < 1}
                onChange={onSeasonChange}
                customFontSize={21}
                size="small"
              />
            </div>
            {filters && filters[0] && filters[0] !== "" && filters[0] !== " " && (
              <div className="class-select">
                <Dropdown
                  title="Selecione uma data"
                  arrowColor="#ffff"
                  textColor="#ffff"
                  backgroundCollor="#171a21"
                  customRadius={30}
                  customHeight={30}
                  customFontSize={11}
                  items={
                    filters
                      ? filters.map((filter) => ({
                          key: filter,
                          value: filter,
                        }))
                      : [{ key: "1", value: "1" }]
                  }
                  defaultValue={firstFilter}
                  isLoading={false}
                  size="small"
                  onChange={(item) => onFilterChage(item)}
                />
              </div>
            )}
          </>
        </FilterContainer>
        <VideosScrollContainer className="hasVerticalScroll">
          {videos.length > 0 &&
            videos.map((video, index) => (
              <VideoCard
                key={video.movieid}
                index={index + 1}
                video={video}
                isWatching={video.position === selectedPosition}
                onSelect={onVideoChange}
                selectedPosition={selectedPosition}
                exercisePreviewActive={video.exerciseshortmessage !== " "}
                onOpen={bla}
              />
            ))}
          {isLoading && videos.length < 1 && (
            <>
              <ShimmerVideoCard type="small" />
              <ShimmerVideoCard type="small" />
              <ShimmerVideoCard type="small" />
              <ShimmerVideoCard type="small" />
              <ShimmerVideoCard type="small" />
            </>
          )}
        </VideosScrollContainer>
      </Content>
    </Container>
  );
};


export default CourseSideMenu;
