import React, {
  useCallback,
  useMemo,
  useRef,
  useEffect,
  Dispatch,
  SetStateAction,
  useState,
} from "react";
import ReactPlayer from "react-player/vimeo";
import { ReactPlayerProps } from "react-player";

import {
  CourseSeasonMovie,
  Course,
  Exercise,
} from "../../../models/CourseModels";
import { useProgress } from "../../../hooks/progress";
import { convertHoursMinutesSecondsToSeconds } from "../../../utils/functions";

import VideoModal from "./VideoModal";

import { Container, VimeoWrapper, ContainerOverlay, EventChat } from "./styles";

interface ViemoComponentProps {
  actualTime?: {
    loaded: number;
    loadedSeconds: number;
    played: number;
    playedSeconds: number;
  };
  isLoading?: boolean;
  isPlaying?: boolean;
  large?: boolean;
  quizzes: Array<Exercise>;
  courseDetails?: Course | undefined;
  url?: string;
  video?: CourseSeasonMovie | undefined;
  onFinish?(info: any): void;
  onPause?(info: any): void;
  setActualTime?:
    | Dispatch<
        SetStateAction<{
          playedSeconds: number;
          played: number;
          loadedSeconds: number;
          loaded: number;
        }>
      >
    | false;
  setIsPlaying?: Dispatch<SetStateAction<boolean>>;
}

const VimeoComponent = ({
  actualTime,
  isLoading,
  isPlaying,
  large = false,
  quizzes = [],
  courseDetails,
  url,
  video,
  onFinish,
  onPause,
  setActualTime,
  setIsPlaying,
}: ViemoComponentProps) => {
  const [isOverlayed, setIsOverlayed] = useState(false);
  const [quizIndex, setQuizIndex] = useState(0);
  const [quiz, setQuiz] = useState(false);
  const [movieid, setMovieid] = useState(" ");

  const { videos } = useProgress();

  const timeToStart = useMemo(() => {
    let timeWatched;

    if (video) {
      if (videos && videos[video.url]) {
        timeWatched = videos[video.url];
      } else if (
        video.courseseasonmovieuser &&
        video.courseseasonmovieuser.videowatched
      ) {
        timeWatched = video.courseseasonmovieuser.videowatched;
      } else {
        timeWatched = "00:00:00";
      }
      const [twHours, twMinutes, twSeconds] = timeWatched.split(":");

      const totalSeconds =
        Number(twHours) * 60 * 60 + Number(twMinutes) * 60 + Number(twSeconds);

      if (
        video.courseseasonmovieuser &&
        video.courseseasonmovieuser.videostatus &&
        video.courseseasonmovieuser.videostatus === "completed"
      ) {
        return 0;
      }

      return totalSeconds;
    }
    return 0;
  }, [video, videos]);

  const autoPlay = useMemo(() => timeToStart > 0, [timeToStart]);

  const vimeoPlayerRef = useRef<ReactPlayerProps>(null);

  const handlePauseVideo = useCallback(
    (info: any) => {
      setIsPlaying && setIsPlaying(false);
      if (info.seconds > timeToStart) {
        console.log("onPause");
        onPause ? onPause(info) : console.log("info");
      }
    },
    [onPause, timeToStart, setIsPlaying]
  );

  const handleEndVideo = useCallback(
    (info: any) => {
      setIsPlaying && setIsPlaying(false);
      onFinish ? onFinish(info) : console.log("end");
    },
    [onFinish, setIsPlaying]
  );

  const handleProgressVideo = useCallback(
    (info: any) => {
      if (isPlaying) {
        setActualTime && setActualTime(info);
      }
    },
    [setActualTime, isPlaying]
  );

  const QuizContainer = useCallback(
    (info: any) => {
      if (video) {
        console.log(videos);
        console.log(video.seasonid);
        console.log(window.location.href);
        setMovieid(video.seasonid);
        if (window.location.href.indexOf(movieid) > -1) {
          setQuiz(true);
          console.log("recebeuo baguio");
        }
      }
    },
    [video, setQuiz]
  );

  const toggleQuizModal = (from: string): void => {
    switch (from) {
      case "timer":
        setIsPlaying && setIsPlaying(false);
        setIsOverlayed(true);

        break;
      case "modal":
        setIsPlaying && setIsPlaying(true);
        setIsOverlayed(false);
        break;
      default:
        break;
    }
  };

  return (
    <Container large={large} from="course">
      {quiz && (
        <ContainerOverlay isVisible={isOverlayed}>
          <VideoModal
            // actualTime={}
            quiz={quizzes[quizIndex]}
            onAction={toggleQuizModal}
          />
        </ContainerOverlay>
      )}
      <VimeoWrapper
        className={`${isOverlayed ? "blured" : ""}`}
        hasChat={url?.includes("event")}
      >
        {url && !isLoading && (
          <>
            <ReactPlayer
              url={url}
              playing={isPlaying}
              progressInterval={950}
              start={timeToStart}
              autoPlay={autoPlay}
              width="100%"
              height="100%"
              controls
              config={{
                playerOptions: {
                  autopause: !autoPlay,
                  autoplay: autoPlay,
                },
                onSeek: timeToStart,
              }}
              ref={vimeoPlayerRef}
              onPause={(info: any) => handlePauseVideo(info)}
              onEnded={(info: any) => handleEndVideo(info)}
              onProgress={(e: any) => handleProgressVideo(e)}
              onPlay={(info: any) => setIsPlaying && setIsPlaying(true)}
              onStart={() => vimeoPlayerRef.current?.seekTo(timeToStart)}
            />
          </>
        )}
      </VimeoWrapper>
      {url?.includes("event") && (
        <EventChat>
          <iframe
            title="chat event container"
            src={video?.chaturl}
            frameBorder="0"
          />
        </EventChat>
      )}
    </Container>
  );
};

export default VimeoComponent;
