import styled, { css } from "styled-components";

interface UserContainerProps {
  bg: string;
}

interface ContainerWrapperProps {
  absolute: boolean;
  sticky?: boolean;
}

export const ContainerWrapper = styled.div<ContainerWrapperProps>`
  transition: all 0.2s;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  width: 100%;
  max-width: 100%;

  ${(props) =>
    props.absolute &&
    css`
      position: absolute;
      z-index: 998;
      width: 100%;
      max-width: 100%;
      height: 80px;
    `}
  ${(props) =>
    props.sticky &&
    css`
      position: fixed;
      background: rgba(0, 0, 0, 60%);
      border-radius: 0 0 4px 4px;
      z-index: 99;
    `};

  @media (max-width: 980px) {
    position: inherit;
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
  max-width: 100%;
  height: 88px;
  max-height: 88px;

  background: linear-gradient(rgba(0, 0, 0, 0.2), transparent);
  background-clip: text;

  &.absolute {
    background: linear-gradient(rgba(0, 0, 0, 0.2), transparent);
  }

  padding: 0 5%;
`;

export const LogoContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  @media (max-width: 768px) {
    justify-content: space-between;
  }

  .horizontalBar {
    border: solid 1px gray;
    height: 56%;
    width: 2px;
    @media (max-width: 425px) {
      display: none;
    }
  }

  .collapsible-wrapper {
    width: 100%;
    height: 56%;
    @media (max-width: 768px) {
      width: max-content;
    }
  }
`;

export const LogoImg = styled.img`
  object-fit: contain;
  width: 70px;
  height: 70px;

  margin-right: 16px;

  transition: filter 0.2s;
  &:hover {
    cursor: pointer;
    filter: opacity(0.6);
  }
  @media (max-width: 768px) {
    width: 3.375rem;
    height: 3.375rem;
  }
`;

export const UserActions = styled.div`
  display: flex;
  align-items: center;
  gap: 25px;

  .logout {
    margin-left: 2.5rem;
    button {
      width: 40px;
      background: transparent;
      border: none;
    }
    @media (max-width: 768px) {
      margin-left: 1rem;
      display: none;
    }
  }

  svg {
    color: #fff;

    transition: filter 0.2s;
    &:hover {
      cursor: pointer;
      filter: opacity(0.6);
    }
  }
`;

export const SignInWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  span {
    color: #fff;
    bottom: -12px;
    margin-left: 8px;
    font-size: 12px;
  }

  svg {
    color: rgb(254, 212, 74, 1);
    font-weight: lighter;
  }

  transition: filter 0.2s;
  &:hover {
    cursor: pointer;
    filter: opacity(0.6);
  }
`;

export const CartContainer = styled.div`
  position: relative;
  margin-left: auto;

  .itens-badge {
    background-color: var(--main_red_color);
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;

    width: 14px;
    height: 14px;

    border-radius: 7px;

    bottom: -2px;
    right: -10px;

    p {
      color: #fff;
      font-size: 10px;
      font-weight: bolder;
      font-family: "Roboto", sans-serif;

      z-index: 100;

      margin: 0;
      padding: 0;

      display: inline;
    }
  }

  transition: filter 0.2s;
  &:hover {
    cursor: pointer;
    filter: opacity(0.6);
  }

  @media (max-width: 768px) {
    z-index: 6;
  }
`;

export const ChildImg = styled.img`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: solid 2px #dbdbdb;
  object-fit: cover;
  flex-shrink: 0;
`;

export const UserContainer = styled.div<UserContainerProps>`
  margin-left: 1.2%;
  width: 350px;
  height: 64px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .user-data-container {
    color: #e9eaed;
    height: 90%;
    width: 80%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    h3 {
      font-size: 16px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      @media (max-width: 768px) {
        font-size: 0.9rem;
      }
    }
    p {
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      @media (max-width: 768px) {
        font-size: 0.8rem;
      }
    }
  }
  @media (max-width: 768px) {
    width: 66%;
    justify-content: flex-start;
  }
`;

export const ImageContainer = styled.div`
  position: relative;
  display: flex;

  flex-shrink: 0;
  margin-right: 10px;

  .main-profile-img {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    transition: 0.4s;
    border: solid 2px #dbdbdb;

    &:hover {
      transform: scale(1.09);
      cursor: pointer;
    }

    @media (max-width: 768px) {
      width: 3.375rem;
      height: 3.375rem;
    }
    @media (max-width: 425px) {
      display: none;
    }
  }

  .child-profile-img {
    position: absolute;
    width: 28px;
    height: 28px;
    background: #000;
    border-radius: 50%;

    bottom: -8px;
    right: -6px;
    transition: 0.4s;

    &:hover {
      transform: scale(1.09);
      cursor: pointer;
    }
    @media (max-width: 768px) {
      width: 1rem;
      height: 1rem;
    }
  }
`;

export const MainImg = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  flex-shrink: 0;
`;

export const UserSchoolNameButton = styled.p`
  transition: 0.4s;
  &:hover {
    letter-spacing: 0.8px;
    cursor: pointer;
    text-shadow: 0 10px 6px rgba(0, 0, 0, 0.4);
  }
`;
