import { WhatsIcon } from "./styles";
import { FaWhatsappSquare } from "react-icons/fa";

const WhatsappFloating = () => {
  let urlAtual =
    process.env.NODE_ENV === "development"
      ? `${process.env.REACT_APP_WHITELABEL}`
      : window.location.href;
  let splitUrlProd = urlAtual.split(".");
//   let subdomain = splitUrlProd[0].includes("www")
//     ? ["", splitUrlProd[1]]
//     : splitUrlProd[0].split("//");
  let subdomain = ["","sedi",""];

  return (
    <WhatsIcon>
      {subdomain[1] === "sedi" ? (
        <a
          href="https://api.whatsapp.com/send?phone=5519989888635"
          target="_blank"
        >
          <FaWhatsappSquare />
        </a>
      ) : (
        <a
          href="https://api.whatsapp.com/send?phone=5519971385444"
          target="_blank"
        >
          <FaWhatsappSquare />
        </a>
      )}
    </WhatsIcon>
  );
};

export default WhatsappFloating;
