import styled from "styled-components";
import Button from "../Button";

export const FlexContainer = styled.div`
  a {
    text-decoration: none;
  }

  color: black;

  border-bottom: solid 1px gray;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-right: 36px;
  border-radius: 4px;

  transition: width 0.4s, height 0.4s;

  width: 100%;
  height: 150px;

  p {
    font-size: 14px;
    font-family: "Roboto Medium", sans-serif;

    margin-top: 8px;
  }

  .courseContent {
    width: 45%;

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;

      & > div {
        display: flex;
        flex-direction: column;
        align-items: baseline;

        p {
          max-width: 100%;
          width: 100%;
        }
      }
    }
  }

  &:hover {
    cursor: pointer;
  }
`;

export const Thumb = styled.img`
  object-fit: cover;

  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  border: solid 0.8px rgba(117, 117, 117, 0.5);
  border-radius: 4px;

  width: 140px;
  height: 85px;
`;

export const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    margin: 0;
    max-width: 70%;
  }
  span {
    margin: 0;
  }

  .nameWithCoupon {
    display: flex;
    flex-direction: column;

    > strong {
      font-size: 10px;
      color: rgba(0, 0, 0, 0.4);
    }
  }

  .oldPrice {
    text-decoration: line-through;
  }
`;

export const StyledButton = styled(Button)`
  display: flex;
  align-items: center;

  width: 108px;
  height: 24px;
  padding: 4px 8px;

  border-radius: 4px;

  background: red;
  color: #fff;

  p {
    margin: 0;
    padding: 0;
    margin-right: 12px;
  }

  @media (max-width: 1200px) {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 40px;
    height: 32px;

    p {
      display: none;
    }

    svg {
      font-weight: bold;
    }
  }
`;
