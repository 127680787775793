import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
  useRef,
} from "react";
import { FaShoppingCart, FaSignInAlt } from "react-icons/fa";
import { FiLogOut } from "react-icons/fi";
import { useHistory, useLocation } from "react-router-dom";

import { useCart } from "../../../hooks/cart";
import { useModal } from "../../../hooks/modal";
import { useAuth } from "../../../hooks/auth";

import { Modal } from "../../Atoms/Modal";
import { CollapsibleMenuV2 } from "../../Atoms/CollapsibleMenuV2";

import ShoppingCart from "../../Mols/ShoppingCart/";
import { toast } from "react-toastify";
import ModalEditSchool from "../Header/Modals/ModalEditSchool";

import {
  ContainerWrapper,
  Container,
  LogoContent,
  LogoImg,
  UserActions,
  SignInWrapper,
  CartContainer,
  ChildImg,
  UserContainer,
  ImageContainer,
  MainImg,
  UserSchoolNameButton,
} from "./styles";
import { useInitialRoute } from "../../../hooks/InitialRouteContext";
import { getUserRole } from "../../../utils/accessRoleHelper";

interface HeaderV2Props {
  absolute?: boolean;
  actualTab?: string;
  tabs?: Array<{ key: string; value: string }>;
  changeTab?(tab: string): void;
}

const HeaderV2: React.FC<HeaderV2Props> = ({
  absolute = false,
  actualTab,
  tabs,
  changeTab,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [scrollPagePos, setScrollPagePos] = useState(false);

  const prevScrollY = useRef(0);

  const { push } = useHistory();
  const { pathname } = useLocation();

  const { cartCourses, isCartOpen, toggleCart, reset } = useCart();
  const { user, signOut } = useAuth();
  const { modalState, toggleModal } = useModal();
  const refCartModal = useRef<any>(null);

  const role = useMemo(() => {
    let getAllTabs = getUserRole(
      user?.profileid,
      user?.levelid,
      user?.schoolid
    );
    return getAllTabs;
  }, [user]);

  const isViewCart = role === "onlyCourses" || role === "courses";

  const schoolName = useMemo(() => {
    if (user) {
      const names = user.schoolid;

      return names;
    }

    return "";
  }, [user]);

  const viewModal = useMemo(() => modalState?.teste, [modalState]);

  const toggleChangeSchoolModal = useCallback(() => {
    const state = modalState?.teste;

    toggleModal("teste", !state);
  }, [modalState, toggleModal]);

  const handleSignIn = useCallback(() => {
    push("/login");
  }, [push]);

  const handleChangeTab = useCallback(
    (tab: string) => {
      if (tab === "plantoes" || tab === "doubts") {
        window.location.href = "https://plantoes.bindemy.com.br";
        return;
      }

      if (tab === "courses") {
        push({
          pathname: `/${tab}`,
          state: { tab },
        });
        return;
      }

      // if (user.status !== "waitingApproval") {
        changeTab && changeTab(tab);

        if (
          (pathname !== `/${tab}` && user.schoolid !== "SETEPEB") ||
          user.schoolid !== "SELETIVO"
        ) {
          push({
            pathname: `/${tab}`,
            state: { tab },
          });
        }
        if (user.schoolid === "SETEPEB" || user.schoolid !== "SELETIVO") {
          push("/liveclasses");
        }
      // } 
      // else {
      //   toast.warning(
      //     "Seu cadastro está em fase de aprovação, aguarde para poder navegar"
      //   );
      // }
    },
    [push, changeTab, pathname, user]
  );

  const handleCollapseMenu = useCallback(() => {
    setIsCollapsed(!isCollapsed);
  }, [isCollapsed]);

  const handleSignOut = useCallback(() => {
    signOut();
    reset();
  }, [signOut]);

  useEffect(() => {
    const tab = pathname.replace("/", "");
    changeTab && changeTab(tab);
  }, [changeTab, pathname, tabs, viewModal]);

  useEffect(() => {
    const handleScroll = (): void => {
      const currentScrollY = window.scrollY;
      const isSticked = currentScrollY < 70;

      if (currentScrollY < 70 && isSticked === scrollPagePos) {
        setScrollPagePos(false);
      }

      if (currentScrollY > 70 && isSticked === scrollPagePos) {
        setScrollPagePos(true);
      }
      prevScrollY.current = currentScrollY;
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => window.removeEventListener("scroll", handleScroll);
  }, [scrollPagePos]);

  const TriggerOutsideCart = (e: any) => {
    const cartWrapper = document.getElementById("cart-container");

    if (
      e.target === refCartModal.current &&
      cartWrapper &&
      cartWrapper.classList.contains("active")
    ) {
      toggleCart();
    }
  };
  const { subdomainUrlImg, hasHome } = useInitialRoute();

  return (
    <ContainerWrapper
      absolute={
        absolute &&
        (window.location.pathname === "/" ||
          window.location.pathname === "/courses")
      }
      sticky={scrollPagePos}
    >
      <Container className={absolute ? "absolute" : ""}>
        <Modal isOpen={viewModal}>
          <ModalEditSchool
            viewModal={viewModal}
            toggleChangeSchoolModal={toggleChangeSchoolModal}
          />
        </Modal>
        <Modal
          isOpen={isCartOpen}
          refProp={refCartModal}
          onClick={(event: any) => TriggerOutsideCart(event)}
        >
          <ShoppingCart handleCart={() => toggleCart(false)} />
        </Modal>
        <LogoContent>
          <LogoImg
            src={subdomainUrlImg}
            alt="nlLogo"
            onClick={() => {
              if (
                window.location.pathname === "/courses" ||
                window.location.pathname === "/"
              ) {
                window.location.reload();
              } else {
                push("/");
              }
            }}
          />
          {user && <div className="horizontalBar" />}
          {user ? (
            <UserContainer bg={user.imageurl}>
              <ImageContainer>
                <div className="main-profile-img">
                  <MainImg
                    src={user.imageurl}
                    alt="user"
                    onClick={() => push("/profile")}
                  />
                </div>
                {user.profileid === "Parent" && (
                  <div className="child-profile-img">
                    <ChildImg
                      src={user.selectedChild?.imageurl}
                      alt="nl-mini-logo"
                      onClick={toggleChangeSchoolModal}
                    />
                  </div>
                )}
              </ImageContainer>
              <div className="user-data-container">
                <h3>{user.fullname}</h3>
                {user.profileid === "Teacher" ? (
                  <UserSchoolNameButton
                    onClick={toggleChangeSchoolModal}
                    className="botao"
                  >
                    {user.schoolName}
                  </UserSchoolNameButton>
                ) : (
                  <>
                    {user.profileid === "Parent" ? (
                      <p>{schoolName}</p>
                    ) : (
                      <p>{user.schoolName}</p>
                    )}
                  </>
                )}
                {user.profileid === "Parent" && user.selectedChild && (
                  <p>{user.selectedChild.fullname}</p>
                )}
                {user.profileid !== "Parent" && <p>{`${user.roomid}`}</p>}
              </div>
            </UserContainer>
          ) : (
            actualTab === "courses" && (
              <button type="button" onClick={handleSignIn} className="botao">
                Login
              </button>
            )
          )}

          <div className="collapsible-wrapper">
            {tabs && actualTab && (
              <CollapsibleMenuV2
                items={tabs}
                handleChangeTab={handleChangeTab}
                handleCollapseMenu={handleCollapseMenu}
                handleLogout={handleSignOut}
                isCollapsed={isCollapsed}
                actualTab={actualTab}
                // canNavigate={user.status !== "waitingApproval"}
                canNavigate={true}
              />
            )}
          </div>

          <UserActions>
            {!user && (
              <SignInWrapper onClick={() => push("/login")}>
                <FaSignInAlt className="signin" size={20} />
                <span>Login</span>
              </SignInWrapper>
            )}
            {isViewCart && (
              <CartContainer onClick={() => toggleCart()}>
                <FaShoppingCart size={24} />
                {cartCourses && cartCourses.courses?.length > 0 && (
                  <div className="itens-badge">
                    <p>{cartCourses.courses?.length}</p>
                  </div>
                )}
              </CartContainer>
            )}
            {user && (
              <div className="logout">
                <button type="button" onClick={handleSignOut} className="botao">
                  <FiLogOut size={20} />
                </button>
              </div>
            )}
          </UserActions>
        </LogoContent>
      </Container>
    </ContainerWrapper>
  );
};

export default HeaderV2;
