import React, { useCallback, useState, useRef } from "react";

import api from "../../../services/api";

import { useAuth } from "../../../hooks/auth";

import { Course as CourseInterface } from "../../../models/CourseModels";
import CourseDetailsModal from "./Modal/CourseDetailsModal";
import { Modal } from "../Modal";

import { useHistory } from "react-router-dom";
import { useToast } from "../../../hooks/toast";

import { useCart } from "../../../hooks/cart";
import {
  FlexContainer,
  Container,
  WrapImgContainer,
  ContentCardWrap,
  PreContentCard,
} from "./syles";
import Button from "../Button";
import ButtonBlocked from "../ButtonBlocked";
import {   MdLockOutline, MdPlayCircleOutline, MdOutlineAddShoppingCart } from "react-icons/md";

interface CourseCardProps {
  course: CourseInterface;
  courseViewType: string;
  customClass?: string;
  id?: string;
}

const CourseCardMobile: React.FC<CourseCardProps> = ({
  course,
  courseViewType,
  customClass,
  id,
}) => {
  const { push } = useHistory();
  const { addToast } = useToast();
  const { addCourse } = useCart();
  const [showContent, setShowContent] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [courseSeasons, setCourseSeasons] = useState([]);
  const { user } = useAuth();
  const { cartCourses } = useCart();
  const refModal = useRef<any>(null);
  const [isPlayer, setIsPlayer] = useState<boolean>(false);

  const handleOpenCourse = useCallback(() => {
    const canOpen = course.comingsoon !== "true" && !course.blocked;
        if (!canOpen) {
          addToast({
            title: course.prerequirementtitle ? course.prerequirementtitle:"Bloqueado",
            type: "info",
      });
    } else {
      user !== undefined
        ? push(`/course/${course.courseid}`)
        : push(`/course/gratis/${course.courseid}`);
    }
  }, [course.courseid, user, push, addToast, course.comingsoon]);

  const getSeasonsCourse = useCallback(async (courseid: any) => {
    const response = await api.get<any>(`/course/season?courseid=${courseid}`);
    const structureSeasons: any = [];

    response.data.map((item: any, key: number) => {
      structureSeasons.push({
        key: key,
        value: item.title,
        season: item.seasonid,
      });
    });

    setCourseSeasons(structureSeasons);
  }, []);

  const triggerModalBtn = (courseid: any) => {
    setShowContent(false);
    getSeasonsCourse(courseid);
    setIsModalOpen(true);
  };

  const addCourseToCart = useCallback(
    (courseToAdd: any) => {
      console.log("Added", courseToAdd);
      addCourse(courseToAdd);
    },
    [addCourse, course]
  );

  const isCourseInCart = (): boolean => {
    const alreadyInCart = cartCourses.courses.find(
      (i: any) => i.courseid === course.courseid
    );

    return !!alreadyInCart;
  };

  window.onclick = function (event) {
    if (event.target === refModal.current) {
      setIsModalOpen(false);
    }
  };

  const handleCloseModalReset = () => {
    setIsModalOpen(false);
    setIsPlayer(false);
  };

  const handlePlayerModal = () => {
    setIsPlayer((prev: boolean) => !prev);
  };

  return (
    <FlexContainer className={`${customClass || ""}`}>
      <Container type={courseViewType}>
        <WrapImgContainer>
          <img
            src={course.thumburlvertical}
            alt={course.thumburlvertical}
            aria-hidden="true"
            onClick={() => triggerModalBtn(course.courseid)}
          />
        </WrapImgContainer>
        {!showContent &&
          (course.comingsoon === "true" ? (
            <PreContentCard>
              <p aria-hidden="true">{course.title}</p>
              <p className="colored" aria-hidden="true">
                EM BREVE
              </p>
            </PreContentCard>
          ) : !course.is_owner ? (
            <PreContentCard>
              <p aria-hidden="true">{course.title}</p>
              <p className="colored" aria-hidden="true">
                R${course.price},00
              </p>
            </PreContentCard>
          ) : (
            <PreContentCard>
              <p aria-hidden="true">{course.title}</p>
            </PreContentCard>
          ))}
        <ContentCardWrap show={showContent}>
          {course.comingsoon === "true" ? (
            <div
              className="card_button"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <p>EM BREVE</p>
            </div>
          ) : !course.is_owner ? (
            <div
              className="card_button"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              {isCourseInCart() === true ? (
                <Button disabled>
                  <MdOutlineAddShoppingCart />
                  Item já adicionado
                </Button>
              ) : (
                <Button onClick={() => addCourseToCart(course)}>
                  <MdOutlineAddShoppingCart />
                  Adicionar ao carrinho
                </Button>
              )}
            </div>
          ) : (
            <div
              className="card_button"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
             {course.blocked ?
                         (<ButtonBlocked onClick={handleOpenCourse}>
                                   <MdLockOutline />
                                        Acesso Bloqueado

                                      </ButtonBlocked>
                                      )
                                      :
                          (<Button onClick={handleOpenCourse}>
                            <MdPlayCircleOutline />
                            Ir para o curso
                          </Button>)}


            </div>
          )}
        </ContentCardWrap>
        <Modal isOpen={isModalOpen}>
          <CourseDetailsModal
            closeModal={() => handleCloseModalReset()}
            setPlayer={() => handlePlayerModal()}
            course={course}
            courseSeasons={courseSeasons}
            openCourseModal={() => handleOpenCourse()}
            addToCart={() => addCourseToCart(course)}
            alreadyInCart={isCourseInCart()}
            refProp={refModal}
            id={id}
            isPlayer={isPlayer}
          />
        </Modal>
      </Container>
    </FlexContainer>
  );
};

export default CourseCardMobile;
