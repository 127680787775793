import { PaymentStatus } from "../../../../../models/PaymentModels";

import * as S from "./styles";

interface IStatusBadge {
  type: PaymentStatus;
}

const StatusBadge: React.FC<IStatusBadge> = ({ type }) => {
  const labelStyles: { [key in PaymentStatus]: string } = {
    ERROR: "Erro",
    FREE: "Grátis",
    WAITING_PAYMENT: "Aguardando Pagamento",
    PAID: "Pago",
  };
  return <S.StatusLabel type={type}>{labelStyles[type]}</S.StatusLabel>;
};

export default StatusBadge;
